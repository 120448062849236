import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

import officeStyle from "assets/jss/material-kit-pro-react/views/aboutUsSections/officeStyle.js";

// office

const useStyles = makeStyles(officeStyle);

export default function CSM() {
  const classes = useStyles();
  return (
    <div className={classes.office}>
      <GridContainer>
        <GridItem
          md={12}
          sm={12}
          className={classNames(classes.mrAuto, classes.mlAuto)}
        >
          Catholic Students Movement (CSM) stands for the overall development of
          the students through its various activities. It is an organization
          primarily for the Catholic Students of our college. The basic
          objective of CSM is the integral personality development of the
          students. To realize this objective CSM has three dimensional
          activities 1.Spiritual and Charitable 2.Intellectual and 3. Cultural.
        </GridItem>
      </GridContainer>
      <h3 className={classes.headding3}> Spiritual </h3>
      <h5>
        Arranging college Morning Prayer service, facilitating Holy Mass on
        first Friday of every month, Rosary Service in October with devotion to
        mother Mary, arrangement of annual retreat are the regular activities of
        CSM.
      </h5>

      <br />
      <br />
      <h3 className={classes.headding3}>Charitable activities</h3>
      <h5>
        As part of the charitable activities of CSM of our college, students
        visit at least two of the old age homes/orphanages in an year , spend
        one day with them, share food, hear them and students perform different
        cultural programs for them, conduct various games for them and give
        prizes for the winners. This year 32 students visited “ Karunalayam” an
        old age home at Koothattukulam and gifted a TV to the inmates along with
        a donation of Rs.5000/-. Around 50 students visited “Deivadan Centre “
        an old age home at Palai and donated Rs.10,000/-. 25 students visited “
        Elena Sapio” an old age home at Palai and food was provided along with a
        donation of Rs. 5000/-.
      </h5>
      <br />
      <br />
      <h3 className={classes.headding3}>Intellectual</h3>
      <h5>
        CSM programs organized with in the college, help the students to develop
        organizational abilities and leadership skills as they are required to
        take charge and involve themselves with all the events conducted.
      </h5>
      <br />
      <br />
      <h3 className={classes.headding3}>Cultural</h3>
      <h5>
        As part of cultural activities of CSM, competitions like Quiz, Crib
        Making, Star Making, Carol ,Angel, Santa etc. were conducted under the
        auspicious of CSM. During Christmas season CSM volunteers collect
        clothes, food and various gifts from the students and distributed at
        nearby poor homes and old age homes and perform different cultural
        programs there too. Beside the CSM unit of Marygiri College of Arts and
        Science organizies various socially relevant and personality development
        oriented programs with the aim of transformating education for its
        members.it tries to achieve this through several outreach programs by
        which the students get practical exposure to the day to day living of
        the under privileged and the marginalized so that they are sensitized
        about the socio-economic realities of our country.
      </h5>
      <hr />
     
    </div>
  );
}
