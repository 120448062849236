import { blackColor } from "assets/jss/material-kit-pro-react";
import {
  container,
  title,
  main,
  mainRaised,
  whiteColor,
  mrAuto,
  mlAuto,
  card,
  cardTitle,
  description,
} from "assets/jss/material-kit-pro-react.js";

const CoursesStyle = {
  main,
  mainRaised,
  mrAuto,
  mlAuto,
  container: {
    ...container,
    zIndex: 1,
    paddingTop: "50px",
  },
  card: {
    ...card,
    padding: "10px",
  },
  cardTitle: {
    ...cardTitle,
  },
  selectUnderlineRoot: {
    "& > div": {
      marginTop: "13px",
    },
  },
  description: {
    ...description,
    fontSize: "16px",
    color: blackColor,
    padding: "15px 0",
  },
  description1: {
    ...description,
    color: whiteColor,
    fontWeight: 500,
    lineHeight: "1.313rem",
  },
  title1: {
    ...title,
    "&, & + h4": {
      color: blackColor,
    },
  },
  title: {
    ...title,
    "&, & + h4": {
      color: whiteColor,
    },
  },
  title2: {
    ...title,
  },
  textCenter: {
    textAlign: "center",
  },
  textRight: {
    textAlign: "right",
  },
  block: {
    color: "inherit",
    padding: "0.9375rem",
    fontWeight: "500",
    fontSize: "12px",
    textTransform: "uppercase",
    borderRadius: "3px",
    textDecoration: "none",
    position: "relative",
    display: "block",
  },
  inlineBlock: {
    display: "inline-block",
    padding: "0px",
    width: "auto",
  },
  list: {
    marginBottom: "0",
    padding: "0",
    marginTop: "0",
  },
  left: {
    float: "left!important",
    display: "block",
  },
  right: {
    padding: "15px 0",
    margin: "0",
    float: "right",
  },
  icon: {
    width: "18px",
    height: "18px",
    top: "3px",
    position: "relative",
  },
  parallax: {
    height: "200px",
  },
  containerLimit: {
    padding: "0 7%",
  },
  footerLogo: {
    width: "80%",
  },
};

export default CoursesStyle;
