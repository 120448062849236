/*eslint-disable*/
import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// @material-ui/icons
import Button from "components/CustomButtons/Button.js";
// core components
import Header from "components/Header/Header.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Parallax from "components/Parallax/Parallax.js";
import Footer from "components/Footer/Footer.js";
// sections for this page
import HeaderLinks2 from "components/Header/HeaderLinks2.js";

import aboutUsStyle from "assets/jss/material-kit-pro-react/views/aboutUsStyle.js";

import logo_white from "assets/img/logo_white.png";
import Clubs from "./Sections/CSM";
import CSM from "./Sections/CSM";

const useStyles = makeStyles(aboutUsStyle);

export default function CSMPage() {
  React.useEffect(() => {
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
  });
  const classes = useStyles();
  return (
    <div>
      <div className={classes.main}>
        <Parallax image={require("assets/img/aboutBg.webp")} small>
          <div className={classes.container}>
            <GridContainer justify="center">
              <GridItem
                md={12}
                sm={12}
                lg={12}
                className={classNames(classes.mlAuto, classes.mrAuto)}
              >
                <div className={classes.containerLimit}>
                  <p className={classes.title}>Home/Clubs</p>
                  <h1 className={classes.title}>Catholic Student Movement</h1>
                </div>
              </GridItem>
            </GridContainer>
          </div>
        </Parallax>

        <div className={classes.container}>
          <CSM />
        </div>
      </div>

      <Footer
        theme="dark"
        content={
          <div>
            <div className={classes.center}>
              Copyright &copy; {1900 + new Date().getYear()}{" "}
              <a href="#" target="_blank" className={classes.aClasses}>
                MaryGiri College,
              </a>{" "}
              All Rights Reserved.
            </div>
          </div>
        }
      >
        <div className={classes.containerLimit}>
          <GridContainer>
            <GridItem xs={12} sm={4} md={4}>
              <img
                className={classes.footerLogo}
                src={logo_white}
                alt="office1"
              />

              <h5>Contact Address</h5>
              <p>
                Koothattukulam P. O. Ernakulam District, Kerala, India PIN - 686
                662
              </p>
              <p>
                0485 2250079, 8086488844 <br />
                marygiricollege@gmail.com
              </p>
            </GridItem>
            <GridItem xs={1} sm={1} md={1}>
              <div
                style={{
                  backgroundColor: "#5c5c5c",
                  width: "1px",
                  height: "100%",
                }}
              />
            </GridItem>
            <GridItem xs={11} sm={3} md={3}>
              <h5>Quick Links</h5>
              <div className={classes.socialFeed}>
                <div>
                  <a href="#/contact-us">
                    <p>Contact Us</p>
                  </a>
                </div>
                <div>
                  <a href="#/online-admission">
                    <p>Online Admission</p>
                  </a>
                </div>
                <div>
                  <p>Online Fee Payment</p>
                </div>
                <div>
                  <a href="#/courses-page">
                    {" "}
                    <p>Courses</p>
                  </a>
                </div>
              </div>
            </GridItem>
            <GridItem xs={1} sm={1} md={1}>
              <div
                style={{
                  backgroundColor: "#5c5c5c",
                  width: "1px",
                  height: "100%",
                }}
              />
            </GridItem>
            <GridItem xs={11} sm={2} md={2}>
              <h5>Follow Us on</h5>
              <div className={classes.socialFeed}>
                <Button
                  color="facebook"
                  round
                  justIcon
                  href="#https://www.facebook.com/marygiricollege1"
                >
                  <i className="fab fa-facebook-f" />
                </Button>
                &nbsp;
                <Button
                  color="instagram"
                  round
                  justIcon
                  href="#https://instagram.com/marygiri_college_official?utm_medium=copy_link"
                >
                  <i className="fab fa-instagram" />
                </Button>
                &nbsp;
                <Button
                  color="twitter"
                  round
                  justIcon
                  href="#https://www.youtube.com/channel/UCEVrpzL3xtWXEwoameOUqUQ"
                >
                  <i className="fab fa-youtube" />
                </Button>
              </div>
            </GridItem>
          </GridContainer>
        </div>
      </Footer>
    </div>
  );
}
