let about = [
    {
        description1:"Marygiri College of Arts and Science, Koothattukulam is an institution managed by CMI Fathers of St. Joseph Province, Kottayam. This institution is an embodiment of the dreams and vision of Saint Kuriakose Elias Chavara, the greatest educational visionary of the 19'th century and the founder of the CMI congregation.",
        description2:"The college aims at forming leaders who are intellectually competent, spiritually mature, morally upright, psychologically integreated, physically healthy and socially acceptable who will champion the cause of justice, love, truth and peace and who are ever open to further growth.",
        description3:"It was started in july 2015 in the premises of Marygiry Public School, Koothattukulam. The college started with five courses viz, B.Com Finance and Taxation Model 1, B.Com Computer Application Model I, BCA, BBA and BA English(Language and Literature Model 1).",
        // description4:"Marygiri College of Arts and Science Koothatukulam is an istitution managed by CMI Fathers of St.Joseph Province,Kottayam. This institutionis ambodimentof the dreams and vision of saint Kuriakos Elias Chavara, The greatest educational visionary of the 19th century and the founder of the CMI congregation."
    },

]
let aboutDescription = [
    {
        description:"The institution takes pride in its well qualified and committed team of teachers, who are always ready to go for an extra mile to ensure the development of the students."

    },
]

let management = [
    {
        description1:"Marygiri College of Arts and Science, Koothattukulam is an institution managed by CMI Fathers of St. Joseph Province, Kottayam. This institution is an embodiment of the dreams and vision of Saint Kuriakose Elias Chavara, the greatest educational visionary of the 19'th century and the founder of the CMI congregation.",
        
        card1name:"Rev.Fr.Jose Parekkattu CMI",
        card1designation:"Campus Director",
        card1image:"",

        card2name:"Rev.Fr. Augustine Mlavarayil CMI",
        card2designation:"Administrator",
        card2image:"",

        card3name:"Prof. Dr. James George",
        card3designation:"Principal",
        card3image:""
    },
]

export { about, management,aboutDescription  }