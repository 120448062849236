/*eslint-disable*/
import React, { useEffect } from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// core components
import Parallax from "components/Parallax/Parallax.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Footer from "components/Footer/Footer.js";
import Button from "components/CustomButtons/Button.js";
import logo_white from "assets/img/logo_white.png";

import CoursesStyle from "assets/jss/material-kit-pro-react/views/CoursesStyle.js";
import Faculty from "./Sections/Faculty";
import { Domain } from "Domain";
import axios from "axios";

const useStyles = makeStyles(CoursesStyle);

export default function AdministrationsPage(props) {
  const [depId, setDepId] = React.useState(props.location.state);
  const classes = useStyles();
  const [faculties, setfaculty] = React.useState([]);
  const [department, setDepartment] = React.useState();
  const [empty, setEmpty] = React.useState(false);
  useEffect(() => {
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
  });
  useEffect(() => {
    if (props.location.state != undefined) {
      setDepId(props.location.state);
    }
  });
  useEffect(() => {
    if (props.location.state == undefined) {
      const departmentId = window.localStorage.getItem("departmentId");
      setDepId(departmentId);
      console.log("local:", departmentId, "depId:", depId);
    }
  }, []);
  useEffect(() => {
    if (props.location.state != undefined)
      window.localStorage.setItem("departmentId", props.location.state);
  });

  useEffect(() => {
    let passData = {
      TypeId: depId,
    };
    // console.log("passData:", passData);

    axios({
      method: "post",
      url: Domain + "/api/Admin/GetAllAdministrationsByTypeId.php",
      data: JSON.stringify(passData),
      config: { headers: { "Content-Type": "multipart/form-data" } },
    })
      .then(function (response) {
        console.log("Administrations::",response.data.Data)
        if(response.data.Data.length>0){
          setEmpty(false);
          setfaculty(response.data.Data);
        }
        else
        setEmpty(true)
        // if (json.Data.length == 0) setEmpty(true);
      
      })
      .catch(function (response) {
        //handle error
        console.log(response);
      });

    // fetch(Domain + "/api/Admin/GetAllAdministrationsByTypeId/", {
    //   method: "POST",
    //   headers: {
    //     Accept: "application/json",
    //     "Content-Type": "application/json",
    //   },
    //   body: JSON.stringify(passData),
    // })
    //   .then((response) => response.json())

    //   .then((json) => {
    //     console.log("Administrations : ", json);
    //     setfaculty(json.Data);
    //   });
  }, [depId]);

  useEffect(() => {
    if (faculties.length == 0) setEmpty(true);
    else setEmpty(false);
  }, [faculties]);
  return (
    <div>
      <Parallax image={require("assets/img/admissionsBg.webp")} small>
        <div className={classes.container}>
          <GridContainer>
            <GridItem
              md={8}
              sm={8}
              lg={12}
              className={classNames(classes.mlAuto, classes.mrAuto)}
            >
              <p className={classes.title}>Home/Administrations</p>
              <h1 className={classes.title}>{department}</h1>
            </GridItem>
          </GridContainer>
        </div>
      </Parallax>
      <div className={classes.main}>
        <div className={classes.container}>
          <GridContainer className={classNames(classes.mlAuto, classes.mrAuto)}>
            <GridItem xs={12} sm={12} md={12}>
              <span> Our Respected {department} Members are listed below</span>
            </GridItem>
          </GridContainer>

          <hr />
          {empty ? (
            <>
              Sorry no Data found!
              <br />
              <br />
            </>
          ) : (
            <Faculty faculties={faculties} setDepartment={setDepartment} />
          )}
        </div>
      </div>
      <br />
      <Footer
        theme="dark"
        content={
          <div>
            <div className={classes.center}>
              Copyright &copy; {1900 + new Date().getYear()}{" "}
              <a href="#" target="_blank" className={classes.aClasses}>
                MaryGiri College,
              </a>{" "}
              All Rights Reserved.
            </div>
          </div>
        }
      >
        <div className={classes.containerLimit}>
          <GridContainer>
            <GridItem xs={12} sm={4} md={4}>
              <img
                className={classes.footerLogo}
                src={logo_white}
                alt="office1"
              />

              <h5>Contact Address</h5>
              <p>
                Koothattukulam P. O. Ernakulam District, Kerala, India PIN - 686
                662
              </p>
              <p>
                0485 2250079, 8086488844 <br />
                marygiricollege@gmail.com
              </p>
            </GridItem>
            <GridItem xs={1} sm={1} md={1}>
              <div
                style={{
                  backgroundColor: "#5c5c5c",
                  width: "1px",
                  height: "100%",
                }}
              />
            </GridItem>
            <GridItem xs={11} sm={3} md={3}>
              <h5>Quick Links</h5>
              <div className={classes.socialFeed}>
                <div>
                  <a href="#/contact-us">
                    <p>Contact Us</p>
                  </a>
                </div>
                <div>
                  <a href="#/online-admission">
                    <p>Online Admission</p>
                  </a>
                </div>
                <div>
                  <p>Online Fee Payment</p>
                </div>
                <div>
                  <a href="#/courses-page">
                    {" "}
                    <p>Courses</p>
                  </a>
                </div>
              </div>
            </GridItem>
            <GridItem xs={1} sm={1} md={1}>
              <div
                style={{
                  backgroundColor: "#5c5c5c",
                  width: "1px",
                  height: "100%",
                }}
              />
            </GridItem>
            <GridItem xs={11} sm={2} md={2}>
              <h5>Follow Us on</h5>
              <div className={classes.socialFeed}>
                <Button
                  color="facebook"
                  round
                  justIcon
                  href="#https://www.facebook.com/marygiricollege1"
                >
                  <i className="fab fa-facebook-f" />
                </Button>
                &nbsp;
                <Button
                  color="instagram"
                  round
                  justIcon
                  href="#https://instagram.com/marygiri_college_official?utm_medium=copy_link"
                >
                  <i className="fab fa-instagram" />
                </Button>
                &nbsp;
                <Button
                  color="twitter"
                  round
                  justIcon
                  href="#https://www.youtube.com/channel/UCEVrpzL3xtWXEwoameOUqUQ"
                >
                  <i className="fab fa-youtube" />
                </Button>
              </div>
            </GridItem>
          </GridContainer>
        </div>
      </Footer>
    </div>
  );
}
