import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

import officeStyle from "assets/jss/material-kit-pro-react/views/aboutUsSections/officeStyle.js";

// office

const useStyles = makeStyles(officeStyle);

export default function NSS() {
  const classes = useStyles();
  return (
    <div className={classes.office}>
      
      <GridContainer>
       
        <GridItem
          md={12}
          sm={12}
          className={classNames(classes.mrAuto, classes.mlAuto)}
        >
          National Service Scheme is a Central Government Educational Programme
          started in the year 1969.It is functioning under the ministry of Youth
          Affairs and Sports at the national level and under the Ministry of
          Higher Education at the State level. With the avowed intention of
          benefitting the socio-cultural environment of its locale, the NSS
          programme actively fosters a spirit of social responsibility in the
          students through activities as varied as social service activities,
          surveys, seven day camps, cleaning activities and awareness classes.
          Students enrolled for the NSS programme are required to partake in a
          minimum of 240 hours of work over a period of two consecutive academic
          years and attend a seven-day special camp within this period. On
          completion of these requirements, the students become eligible for
          certificate from the University, which will also guarantee them
          weightage marks as per the University guidelines.
        </GridItem>
      </GridContainer>
      <h3 className={classes.headding3}> Aim </h3>
      <h5>
        The overall aim of National Service Scheme is the development of the
        personality of students through community service.
      </h5>

      <br />
      <br />
      <h3 className={classes.headding3}>Mission</h3>
      <h5>
        National Service Scheme has been functioning with the motto “ NOT ME BUT
        YOU ” in view of making the youth inspired in service of the people and
        hence NSS aims education through community service and community service
        through education. No of Unit: 01 Number of Volunteers: 50 Name of
        Programme officers: 1. Mr. Arun K Prabhakar , Assistant Professor,
        Department of Commerce and Management 2. Mrs. Anju Subin, Assistant
        Professor, Department of Commerce and Management In recent past we have
        conducted many programs. Few among them are:- World Environment day Anti
        –Drug Day Programme World Population Day Celebration Cleaning and
        beautification of college campus Flood relief materials to flood
        affected families Seven day special online camp
        <br />
        <h5 className={classes.headding3}>No of Unit: 01</h5>
        <br />
        <h5 className={classes.headding3}>Number of Volunteers: 50</h5>
        <br />
        <h5 className={classes.headding3}>Name of Programme officers:</h5>
        <h6 className={classes.headding3}>1. Mr. Arun K Prabhakar </h6>{" "}
        Assistant Professor, Department of Commerce and Management
        <h6 className={classes.headding3}>2. Mrs. Anju Subin </h6> Assistant
        Professor, Department of Commerce and Management
        <br />
        <h6 className={classes.headding3}>
          In recent past we have conducted many programs. Few among them are:-
          World Environment day <br />
          Anti –Drug Day Programme
          <br />
          World Population Day Celebration
          <br />
          Cleaning and beautification of college campus
          <br />
          Flood relief materials to flood affected families
          <br />
          Seven day special online camp
          <br />
        </h6>
      </h5>
    
    </div>
  );
}
