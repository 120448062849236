/*eslint-disable*/
import React, { useState } from "react";
// @material-ui/core components
import { Domain } from "Domain";
import { makeStyles } from "@material-ui/core/styles";
import InputAdornment from "@material-ui/core/InputAdornment";
import Icon from "@material-ui/core/Icon";
// @material-ui/icons
import Face from "@material-ui/icons/Face";
// core components

import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";

import Close from "@material-ui/icons/Close";

import loginPageStyle from "assets/jss/material-kit-pro-react/views/loginPageStyle.js";
import CardFooter from "components/Card/CardFooter";
import Danger from "components/Typography/Danger";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
const useStyles = makeStyles(loginPageStyle);

export default function PopUpLogin({
  setLoggedin,
  setUserdetails,
  setLoginModal,
}) {
  const [invalid, setInvalid] = useState(false);
  const [data, setData] = useState({
    ClassNo: "",
    DateOfBirth: "",
  });
  function HandleData(e) {
    const newData = { ...data };
    newData[e.target.id] = e.target.value;
    setData(newData);
    console.log(newData);
  }
  React.useEffect(() => {
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
  });
  function HandleLogin() {
    if (data.ClassNo == "" || data.DateOfBirth == "") {
      setInvalid(true);
      setLoggedin(false);
    } else {
      fetch(
        Domain + "/api/Student/StudentLogin/",
        {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
          body: JSON.stringify(data),
        }
      )
        .then((response) => response.json())

        .then((json) => {
          console.log("user:", json);
          setUserdetails(json.Data);
          setData({
            Id: 0,
            ClassNo: "",
            DateOfBirth: "",
          });
          if (json.Success) {
            setInvalid(false);
            setLoggedin(true);
          } else {
            setInvalid(true);
            setLoggedin(false);
          }
        });
    }
  }
  const classes = useStyles();
  return (
    <Card plain className={classes.modalLoginCard}>
      <DialogTitle
        id="login-modal-slide-title"
        disableTypography
        className={classes.modalHeader}
      >
        <CardHeader
          color="primary"
          className={classes.textCenter + " " + classes.cardLoginHeader}
        >
          <h5 className={classes.cardTitleWhite}>Sign in</h5>
        </CardHeader>
      </DialogTitle>
      <DialogContent
        id="login-modal-slide-description"
        className={classes.modalBody}
      >
        <form>
          <CardBody className={classes.cardLoginBody}>
            <CustomInput
              error={invalid}
              onChange={(e) => HandleData(e)}
              value={data.ClassNo}
              id="ClassNo"
              formControlProps={{
                fullWidth: true,
              }}
              inputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Face className={classes.icon} />
                  </InputAdornment>
                ),
                placeholder: "Class Number",
              }}
            />
            <CustomInput
              error={invalid}
              onChange={(e) => HandleData(e)}
              value={data.DateOfBirth}
              id="DateOfBirth"
              formControlProps={{
                fullWidth: true,
              }}
              inputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Icon className={classes.icon}>lock_outline</Icon>
                  </InputAdornment>
                ),
                placeholder: "Date Of Birth",
              }}
            />
          </CardBody>
          <CardFooter>
            <GridContainer>
              <GridItem xs={12} sm={12} md={12}>
                {invalid ? <Danger>Invalid Sign in details!</Danger> : <></>}
              </GridItem>

              <GridItem xs={12} sm={12} md={12}>
                <Button onClick={() => setLoginModal(false)}>Cancel</Button>
                <Button color="primary" onClick={HandleLogin}>
                  Sign in
                </Button>
              </GridItem>
            </GridContainer>
          </CardFooter>
        </form>
      </DialogContent>
      <DialogActions
        className={classes.modalFooter + " " + classes.justifyContentCenter}
      ></DialogActions>
    </Card>
  );
}
