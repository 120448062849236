/* eslint-disable */
import React, { useEffect, useState } from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import { Domain } from "Domain";
import { grayColor } from "assets/jss/material-kit-pro-react";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";

import placementSection from "assets/jss/material-kit-pro-react/views/landingPageSections/placementStyle.js";
import { whiteColor } from "assets/jss/material-kit-pro-react";

const useStyles = makeStyles(placementSection);
export default function ManagementSection({ management }) {
  const classes = useStyles();

  return (
    <div className="cd-section">
      <div
        className={
          classes.projects + " " + classes.sectionDark + " " + classes.projects3
        }
      >
        <div className={(classes.containerm, classes.containerLimit)}>
          <GridContainer>
            <GridItem
              xs={12}
              sm={12}
              md={12}
              className={classes.mlAuto + " " + classes.mrAuto}
            >
              <h2 className={classes.title}>Management</h2>
              <h4 style={{ color: whiteColor }}>
                {" "}
                Marygiri College of Arts and Science, Koothattukulam is an
                institution managed by CMI Fathers of St. Joseph Province,
                Kottayam. This institution is an embodiment of the dreams and
                vision of Saint Kuriakose Elias Chavara, the greatest
                educational visionary of the 19'th century and the founder of
                the CMI congregation.{" "}
              </h4>
            </GridItem>
          </GridContainer>
          {/* Map from here */}

          <GridContainer>
            {management.map((item, i) => (
              <GridItem
                xs={12}
                sm={3}
                md={3}
                className={
                  classes.mlAuto +
                  " " +
                  classes.mrAuto +
                  " " +
                  classes.textCenter
                }
              >
                <Card pricing className={classes.card}>
                  <CardBody pricing>
                    <img
                      src={Domain + item.Image}
                      alt="..."
                      className={
                        classes.imgRoundedCircle + " " + classes.imgFluid
                      }
                    />
                  </CardBody>
                  <h4 className={classes.title2}> {item.Post} </h4>
                  <h4 className={classes.title} style={{ color: grayColor[1] }}>
                    {" "}
                    {item.Name}{" "}
                  </h4>
                </Card>
              </GridItem>
            ))}
          </GridContainer>
        </div>
      </div>
    </div>
  );
}
