import React from "react";
// nodejs library that concatenates classes

import classNames from "classnames";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

import officeStyle from "assets/jss/material-kit-pro-react/views/aboutUsSections/officeStyle.js";

// office

import Reetha from "assets/img/StaffsImages/Reetha.jpg";
import Sunitha from "assets/img/StaffsImages/Sunitha.jpg";

const useStyles = makeStyles(officeStyle);

export default function ComputerLab() {
  const classes = useStyles();
  return (
    <div className={classes.office}>
      <h3 className={classes.headding3}> Lab Timing </h3>
      <h5>
        Monday – Friday – 9.00 am – 4.30 pm
        <br />
        Saturdays – 10.00 am – 1.00 pm
      </h5>
      <br />
      <h3 className={classes.headding3}> Lab Rules </h3>
      <h5>
        <li>Strict silence should be observed in and around the Lab</li>
        <li>
          Students, who enter the lab should write their Name, roll no and
          system no in the Lab Register promptly
        </li>
        <li>
          Students should keep their personal belongings outside the lab and
          bring only the lab assignment related books with you to the lab.
        </li>
        <li>Students are not allowed to bring food & drinks into the Lab.</li>
        <li>Mobile Phones and Laptops are not allowed in the Lab</li>
        <li>
          Computer labs are to be used only for academic purposes, not allowed
          to use the lab resources or printers for personal use.
        </li>
        <li>
          Students are supposed to use the systems allotted to them, only during
          lab hours.
        </li>
        <li>
          Students must save their data on their shared folders, no programs or
          data are to be stored on the local hard drives, not allowed to use any
          removable storage devices on the lab computers
        </li>
        <li>
          Make changing of System Settings, desktop personalizing, download,
          install or use of any outside software’s, games, chat rooms or
          messengers are strictly prohibited
        </li>
        <li>
          Students are not permitted to rearrange or replace or misplace any of
          the computer peripherals such as monitor, CPU, keyboard, mouse,
          printer, cables, etc. Else seek the help of the System Administrator
          under unavoidable circumstances.
        </li>
        <li>
          Before leaving the Lab, users should properly Shutdown the Systems and
          arrange your chairs properly.
        </li>
      </h5>
      <br />
      <GridContainer>
        <GridItem
          md={12}
          sm={12}
          className={classNames(classes.mrAuto, classes.mlAuto)}
        >
          <h2 className={classes.title}>Supporters</h2>
        </GridItem>
      </GridContainer>
      <GridContainer>
        <GridItem
          xs={12}
          md={6}
          sm={6}
          className={classNames(classes.mrAuto, classes.mlAuto)}
        >
          <center>
            <img src={Reetha} alt="" width="250px" />
            <br />
            <h3>Reethamma A C </h3>
          </center>
        </GridItem>
        <GridItem
          xs={12}
          md={6}
          sm={6}
          className={classNames(classes.mrAuto, classes.mlAuto)}
        >
          <center>
            <img src={Sunitha} alt="" width="250px" />
            <br />
            <h3>Sunitha Mohan</h3>
          </center>
        </GridItem>
      </GridContainer>
    </div>
  );
}
