/*eslint-disable*/
import React, { useEffect } from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// core components

import Parallax from "components/Parallax/Parallax.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Footer from "components/Footer/Footer.js";
import Button from "components/CustomButtons/Button.js";
import LoadingOverlay from "react-loading-overlay";
import CoursesStyle from "assets/jss/material-kit-pro-react/views/CoursesStyle.js";
import { Card } from "@material-ui/core";
import CardHeader from "components/Card/CardHeader";
import CardBody from "components/Card/CardBody";
import CustomFileInput from "components/CustomFileInput/CustomFileInput";
import CustomInput from "components/CustomInput/CustomInput";
import Danger from "components/Typography/Danger";
import CardFooter from "components/Card/CardFooter";
import SingleSelect from "components/SingleSelect";
import { Domain } from "Domain";
import axios from "axios";
import { Dialog } from "@material-ui/core";
import ClipLoader from "react-spinners/ClipLoader";

import AttachFile from "@material-ui/icons/AttachFile";
import Success from "components/Typography/Success";
import Primary from "components/Typography/Primary";
const useStyles = makeStyles(CoursesStyle);
import done from "assets/img/done.gif";
import pleaseWait from "assets/img/pleaseWait.gif";
export default function OnlineAdmissionPage() {
  // React.useEffect(() => {
  //   window.scrollTo(0, 0);
  //   document.body.scrollTop = 0;
  // });
  const classes = useStyles();
  const [files, setFiles] = React.useState(null);
  const [files2, setFiles2] = React.useState(null);
  const [files1, setFiles1] = React.useState(null);
  const [step, setStep] = React.useState(1);
  const [invalid, setInvalid] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [loading1, setLoading1] = React.useState(false);
  const [loading2, setLoading2] = React.useState(false);
  const [uploaded, setUploaded] = React.useState("");
  const [uploaded1, setUploaded1] = React.useState("");
  const [uploaded2, setUploaded2] = React.useState("");
  const [loginModal, setLoginModal] = React.useState(false);
  const [success, setSuccess] = React.useState(false);
  const [admission, setAdmission] = React.useState([]);
  const [data, setData] = React.useState({
    Id: 0,
    FirstName: "",
    SecondName: "",
    Gender: "",
    DOB: "",
    Nationality: "",
    Religion: "",
    Caste: "",
    FathersName: "",
    MothersName: "",
    FatherMobile: "",
    StudentMobile: "",
    Email: "",
    AddressType: "",
    Country: "",
    State: "",
    District: "",
    City: "",
    Street: "",
    HouseName: "",
    PostOffice: "",
    PostalCode: "",
    IdentificationMark: "",
    Course: "",
    CourseId: "",
    SSLCMark: "",
    SSLCFile: "",
    PlusTwoMark: "",
    PlusTwoFile: "",
    ExtraActivities: "",
    ExrtaFile: "",
    Note: "",
    LastStatus: "Requested",
    Status: "Created",
    Image: "",
    StatusList: "",
    Step: 1,
    PlusTwoSchool: "",
    Board: "",
    RegisterNoPlusTwo: "",
    YearOfPassingPlusTwo: "",
    SSLCSchool: "",
    SSLCBoard: "",
    RegisterNoSSLC: "",
    YearOfPassingSSLC: "",
  });
  // console.log("file:", files);

  useEffect(() => {
    //function to upload plustwo certificate
    if (files != null) {
      setLoading(true);
      setInvalid(false);
      // setSaving(true);
      let form_data = new FormData();
      form_data.append("File", files[0]);
      let url = Domain + "/api/Uploads/File/";
      axios
        .post(url, form_data, {
          headers: {
            "content-type": "multipart/form-data",
          },
        })
        .then((res) => {
          console.log("upload file:", res);
          if (res.data.Success) {
            data.PlusTwoFile = res.data.Data[0];
            // setUploaded(true);
            // HandleSave();
            console.log("success", res.data.Data[0]);
            HandleNext();
            setUploaded("File Uploaded!");
            setLoading(false);
          } else {
            // setUploaded(false);
            console.log("failed", res.data.Data[0]);
            setUploaded("Failed to upload!");
            setLoading(false);

            // setInvalid(false);
          }
        })
        .catch((err) => {
          console.log("error:", err);
          setUploaded("Failed to upload!");
          setLoading(false);

          // setUploaded(false);
        });
    }
    // else if (data.Image != "") HandleSave();
    // else setInvalid(true);
    // setLoading(false);
  }, [files]);

  useEffect(() => {
    //function to upload sslc certificate
    if (files1 != null) {
      setLoading1(true);
      setInvalid(false);
      // setSaving(true);
      let form_data = new FormData();
      form_data.append("File", files1[0]);
      let url = Domain + "/api/Uploads/File/";
      axios
        .post(url, form_data, {
          headers: {
            "content-type": "multipart/form-data",
          },
        })
        .then((res) => {
          console.log("upload file:", res);
          if (res.data.Success) {
            data.SSLCFile = res.data.Data[0];
            // setUploaded(true);
            // HandleSave();
            console.log("success", res.data.Data[0]);

            setUploaded1("File Uploaded!");
            setLoading1(false);
          } else {
            // setUploaded(false);
            console.log("failed", res.data.Data[0]);
            setUploaded1("Failed to upload!");
            setLoading1(false);

            // setInvalid(false);
          }
        })
        .catch((err) => {
          console.log("error:", err);
          setUploaded1("Failed to upload!");
          setLoading1(false);

          // setUploaded(false);
        });
    }
    // else if (data.Image != "") HandleSave();
    // else setInvalid(true);
    // setLoading(false);
  }, [files1]);
  useEffect(() => {
    //function to upload Student photo
    if (files2 != null) {
      setLoading2(true);
      setInvalid(false);
      // setSaving(true);
      let form_data = new FormData();
      form_data.append("File", files2[0]);
      let url = Domain + "/api/Uploads/File/";
      axios
        .post(url, form_data, {
          headers: {
            "content-type": "multipart/form-data",
          },
        })
        .then((res) => {
          console.log("upload file:", res);
          if (res.data.Success) {
            data.Image = res.data.Data[0];
            // setUploaded(true);
            // HandleSave();
            console.log("success", res.data.Data[0]);

            setUploaded2("File Uploaded!");
            setLoading2(false);
          } else {
            // setUploaded(false);
            console.log("failed", res.data.Data[0]);
            setUploaded2("Failed to upload!");
            setLoading2(false);

            // setInvalid(false);
          }
        })
        .catch((err) => {
          console.log("error:", err);
          setUploaded2("Failed to upload!");
          setLoading2(false);

          // setUploaded(false);
        });
    }
    // else if (data.Image != "") HandleSave();
    // else setInvalid(true);
    // setLoading(false);
  }, [files2]);

  //function to upload plustwo certificate
  // function UploadPlustwoFile() {
  //   if (files != null) {
  //     setInvalid(false);
  //     // setSaving(true);
  //     let form_data = new FormData();
  //     form_data.append("File", files[0]);
  //     let url = Domain + "/api/Uploads/File/";
  //     axios
  //       .post(url, form_data, {
  //         headers: {
  //           "content-type": "multipart/form-data",
  //         },
  //       })
  //       .then((res) => {
  //         console.log("upload file:", res);
  //         if (res.data.Success) {
  //           data.PlusTwoFile = res.data.Data[0];
  //           // setUploaded(true);
  //           // HandleSave();
  //           console.log("success", res.data.Data[0]);
  //           HandleNext();
  //         } else {
  //           // setUploaded(false);
  //           console.log("failed", res.data.Data[0]);
  //           // setInvalid(false);
  //         }
  //       })
  //       .catch((err) => {
  //         console.log("error:", err);
  //         // setUploaded(false);
  //       });
  //   }
  //   // else if (data.Image != "") HandleSave();
  //   else setInvalid(true);
  // }
  function HandleClear() {
    setData({
      Id: 0,
      FirstName: "",
      SecondName: "",
      Gender: "",
      DOB: "",
      Nationality: "",
      Religion: "",
      Caste: "",
      FathersName: "",
      MothersName: "",
      FatherMobile: "",
      StudentMobile: "",
      Email: "",
      AddressType: "",
      Country: "",
      State: "",
      District: "",
      City: "",
      Street: "",
      HouseName: "",
      PostOffice: "",
      PostalCode: "",
      IdentificationMark: "",
      Course: "",
      CourseId: "",
      SSLCMark: "",
      SSLCFile: "",
      PlusTwoMark: "",
      PlusTwoFile: "",
      ExtraActivities: "",
      ExrtaFile: "",
      Note: "",
      LastStatus: "Requested",
      Status: "Created",
      Image: "",
      StatusList: "",
      Step: 1,
    });
  }
  function ValidateStep1() {
    if (data.FirstName == "") return false;
    else if (data.SecondName == "") return false;
    else if (data.Gender == "") return false;
    else if (data.DOB == "") return false;
    else if (data.Nationality == "") return false;
    else if (data.FathersName == "") return false;
    else if (data.MothersName == "") return false;
    else if (data.FatherMobile == "") return false;
    else if (data.StudentMobile == "") return false;
    else if (data.Email == "") return false;
    else if (data.Country == "") return false;
    else if (data.State == "") return false;
    else if (data.District == "") return false;
    else if (data.City == "") return false;
    else if (data.Street == "") return false;
    else if (data.HouseName == "") return false;
    else if (data.PostOffice == "") return false;
    else if (data.PostalCode == "") return false;
    else if (data.IdentificationMark == "") return false;
    else if (data.Course == "") return false;
    else if (data.CourseId == "") return false;
    else if (data.SSLCMark == "") return false;
    else if (data.SSLCFile == "") return false;
    else if (data.Image == "") return false;
    else return true;
  }
  function HandleNext() {
    if (ValidateStep1()) {
      setLoginModal(true);
      setInvalid(false);
      //save function
      fetch(Domain + "/api/Student/CreateAdmissionDetails/", {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      })
        .then((response) => response.json())

        .then((json) => {
          console.log(json);
          setData(json.Data);
          setLoginModal(false);
          setSuccess(true);
        });
    } else setInvalid(true);
  }
  // function HandleBack() {
  //   if (step > 1) setStep(step - 1);
  // }
  function HandleData(e) {
    const newData = { ...data };
    newData[e.target.id] = e.target.value;
    setData(newData);
    console.log(newData);
  }
  const [Courses, setCourses] = React.useState([]);
  const CourseList = Courses.map((d) => ({
    value: d.Id,
    label: d.CourseName,
  }));
  const [CourseValues, setCourseValues] = React.useState({
    Id: null,
    Label: "",
  });
  useEffect(() => {
    setData((data) => ({
      ...data,
      CourseId: CourseValues.Id,
      Course: CourseValues.Label,
    }));
  }, [CourseValues.Id]);
  useEffect(() => {
    let passData2 = {
      PageIndex: 0,
      PageSize: 0,
    };
    //API call for get all course names to dropedown
    fetch(Domain + "/api/Admin/GetAllCourses/", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(passData2),
    })
      .then((response) => response.json())

      .then((json) => {
        if (json.Data.length != 0) setCourses(json.Data);
      });
  }, []);

  return (
    <div>
      <Dialog
        classes={{
          root: classes.modalRoot,
          paper: classes.modal + " " + classes.modalLogin,
        }}
        open={loginModal}
        keepMounted
        onClose={() => setLoginModal(false)}
        aria-labelledby="login-modal-slide-title"
        aria-describedby="login-modal-slide-description"
      >
        <Card>
          <CardBody>
            <GridContainer>
              <GridItem>
                <img style={{ height: "250px" }} src={pleaseWait} />

                <h5>Application is Submiting...</h5>
              </GridItem>
            </GridContainer>
          </CardBody>
        </Card>
      </Dialog>
      <Parallax image={require("assets/img/admissionsBg.webp")} small>
        <div className={classes.container}>
          <GridContainer>
            <GridItem
              md={8}
              sm={8}
              lg={12}
              className={classNames(classes.mlAuto, classes.mrAuto)}
            >
              <p className={classes.title}>Home/Application</p>
              <h1 className={classes.title}>Apply Online</h1>
            </GridItem>
          </GridContainer>
        </div>
      </Parallax>
      <div className={classes.main}>
        <div className={classes.container}>
          <GridContainer className={classNames(classes.mlAuto, classes.mrAuto)}>
            <GridItem xs={12} sm={12} md={12}>
              {success ? (
                <GridContainer
                  className={classNames(classes.mlAuto, classes.mrAuto)}
                >
                  <GridItem style={{ textAlign: "center" }}>
                    {" "}
                    <img style={{ height: "200px" }} src={done} />
                    <h4>
                      Hi <b>{data.FirstName}</b>
                    </h4>
                    <h3>Your Application Submitted Successfully! </h3>
                    Your Admission Id is <b>{data.AdmissionNo}</b>
                  </GridItem>
                </GridContainer>
              ) : (
                <GridContainer
                  className={classNames(classes.mlAuto, classes.mrAuto)}
                >
                  <GridItem xs={12} sm={12} md={12}>
                    <Card>
                      <form>
                        <CardHeader color="primary">
                          <br />
                          <h4 className={classes.cardTitleWhite}>
                            Primary details
                          </h4>
                        </CardHeader>
                        <CardBody>
                          <GridContainer>
                            <GridItem xs={12} sm={12} md={6}>
                              <CustomInput
                                onChange={(e) => HandleData(e)}
                                value={data.FirstName}
                                labelText="First Name"
                                id="FirstName"
                                formControlProps={{
                                  fullWidth: true,
                                }}
                              />
                            </GridItem>
                            <GridItem xs={12} sm={12} md={6}>
                              <CustomInput
                                onChange={(e) => HandleData(e)}
                                value={data.SecondName}
                                labelText="Second Name"
                                id="SecondName"
                                formControlProps={{
                                  fullWidth: true,
                                }}
                              />
                            </GridItem>
                            <GridItem xs={12} sm={12} md={3}>
                              <CustomInput
                                onChange={(e) => HandleData(e)}
                                value={data.StudentMobile}
                                labelText="Student's Mobile"
                                id="StudentMobile"
                                formControlProps={{
                                  fullWidth: true,
                                }}
                              />
                            </GridItem>
                            <GridItem xs={12} sm={12} md={3}>
                              <CustomInput
                                onChange={(e) => HandleData(e)}
                                value={data.Email}
                                labelText="Email"
                                id="Email"
                                formControlProps={{
                                  fullWidth: true,
                                }}
                              />
                            </GridItem>
                            <GridItem xs={12} sm={12} md={6}>
                              <SingleSelect
                                noOptionsMessage="Create any course first"
                                placeholder="Select Course"
                                Options={CourseList}
                                setValue={setCourseValues}
                                formControlProps={{
                                  fullWidth: true,
                                }}
                              />
                            </GridItem>
                          </GridContainer>
                        </CardBody>
                        <br />
                        <br />
                        <CardHeader color="primary">
                          <h4 className={classes.cardTitleWhite}>
                            Personal details
                          </h4>
                        </CardHeader>
                        <CardBody>
                          <GridContainer>
                            <GridItem xs={12} sm={12} md={4}>
                              <CustomFileInput
                                setFiles={setFiles2}
                                formControlProps={{
                                  fullWidth: true,
                                }}
                                inputProps={{
                                  placeholder:
                                    "Upload Student's Photo ( Passport size )",
                                }}
                                endButton={{
                                  buttonProps: {
                                    round: true,
                                    color: "primary",
                                    justIcon: true,
                                  },
                                  icon: <AttachFile />,
                                }}
                              />
                              {loading2 ? (
                                <Success>Uploading Photo...</Success>
                              ) : (
                                <>{uploaded2}</>
                              )}
                            </GridItem>

                            <GridItem xs={12} sm={12} md={4}>
                              <CustomInput
                                onChange={(e) => HandleData(e)}
                                value={data.Gender}
                                labelText="Gender"
                                id="Gender"
                                formControlProps={{
                                  fullWidth: true,
                                }}
                              />
                            </GridItem>
                            <GridItem xs={12} sm={12} md={4}>
                              <CustomInput
                                onChange={(e) => HandleData(e)}
                                value={data.DOB}
                                labelText="Date Of Birth (dd/mm/yyyy) "
                                id="DOB"
                                formControlProps={{
                                  fullWidth: true,
                                }}
                              />
                            </GridItem>
                            <GridItem xs={12} sm={12} md={4}>
                              <CustomInput
                                onChange={(e) => HandleData(e)}
                                value={data.IdentificationMark}
                                labelText="Identification Mark"
                                id="IdentificationMark"
                                formControlProps={{
                                  fullWidth: true,
                                }}
                              />
                            </GridItem>
                            <GridItem xs={12} sm={12} md={4}>
                              <CustomInput
                                onChange={(e) => HandleData(e)}
                                value={data.Nationality}
                                labelText="Nationality"
                                id="Nationality"
                                formControlProps={{
                                  fullWidth: true,
                                }}
                              />
                            </GridItem>

                            <GridItem xs={12} sm={12} md={4}>
                              <CustomInput
                                onChange={(e) => HandleData(e)}
                                value={data.Religion}
                                labelText="Religion"
                                id="Religion"
                                formControlProps={{
                                  fullWidth: true,
                                }}
                              />
                            </GridItem>
                            <GridItem xs={12} sm={12} md={4}>
                              <CustomInput
                                onChange={(e) => HandleData(e)}
                                value={data.Caste}
                                labelText="Caste"
                                id="Caste"
                                formControlProps={{
                                  fullWidth: true,
                                }}
                              />
                            </GridItem>
                            <GridItem xs={12} sm={12} md={4}>
                              <CustomInput
                                onChange={(e) => HandleData(e)}
                                value={data.FathersName}
                                labelText="Father's Name"
                                id="FathersName"
                                formControlProps={{
                                  fullWidth: true,
                                }}
                              />
                            </GridItem>
                            <GridItem xs={12} sm={12} md={4}>
                              <CustomInput
                                onChange={(e) => HandleData(e)}
                                value={data.MothersName}
                                labelText="Mother's Name"
                                id="MothersName"
                                formControlProps={{
                                  fullWidth: true,
                                }}
                              />
                            </GridItem>

                            <GridItem xs={12} sm={12} md={4}>
                              <CustomInput
                                onChange={(e) => HandleData(e)}
                                value={data.FatherMobile}
                                labelText="Father's Mobile"
                                id="FatherMobile"
                                formControlProps={{
                                  fullWidth: true,
                                }}
                              />
                            </GridItem>
                          </GridContainer>
                        </CardBody>
                        <br />
                        <br />
                        <CardHeader color="primary">
                          <h4 className={classes.cardTitleWhite}>
                            Permenent Address details
                          </h4>
                        </CardHeader>
                        <CardBody>
                          <GridContainer>
                            <GridItem xs={12} sm={12} md={4}>
                              <CustomInput
                                onChange={(e) => HandleData(e)}
                                value={data.Country}
                                labelText="Country"
                                id="Country"
                                formControlProps={{
                                  fullWidth: true,
                                }}
                              />
                            </GridItem>
                            <GridItem xs={12} sm={12} md={4}>
                              <CustomInput
                                onChange={(e) => HandleData(e)}
                                value={data.State}
                                labelText="State"
                                id="State"
                                formControlProps={{
                                  fullWidth: true,
                                }}
                              />
                            </GridItem>
                            <GridItem xs={12} sm={12} md={4}>
                              <CustomInput
                                onChange={(e) => HandleData(e)}
                                value={data.District}
                                labelText="District"
                                id="District"
                                formControlProps={{
                                  fullWidth: true,
                                }}
                              />
                            </GridItem>
                            <GridItem xs={12} sm={12} md={4}>
                              <CustomInput
                                onChange={(e) => HandleData(e)}
                                value={data.City}
                                labelText="City"
                                id="City"
                                formControlProps={{
                                  fullWidth: true,
                                }}
                              />
                            </GridItem>
                            <GridItem xs={12} sm={12} md={4}>
                              <CustomInput
                                onChange={(e) => HandleData(e)}
                                value={data.Street}
                                labelText="Street"
                                id="Street"
                                formControlProps={{
                                  fullWidth: true,
                                }}
                              />
                            </GridItem>
                            <GridItem xs={12} sm={12} md={4}>
                              <CustomInput
                                onChange={(e) => HandleData(e)}
                                value={data.HouseName}
                                labelText="House Name"
                                id="HouseName"
                                formControlProps={{
                                  fullWidth: true,
                                }}
                              />
                            </GridItem>
                            <GridItem xs={12} sm={12} md={4}>
                              <CustomInput
                                onChange={(e) => HandleData(e)}
                                value={data.PostOffice}
                                labelText="Post Office"
                                id="PostOffice"
                                formControlProps={{
                                  fullWidth: true,
                                }}
                              />
                            </GridItem>
                            <GridItem xs={12} sm={12} md={4}>
                              <CustomInput
                                onChange={(e) => HandleData(e)}
                                value={data.PostalCode}
                                labelText="Postal Code"
                                id="PostalCode"
                                formControlProps={{
                                  fullWidth: true,
                                }}
                              />
                            </GridItem>
                          </GridContainer>
                        </CardBody>
                        <br />
                        <br />
                        <CardHeader color="primary">
                          <h4 className={classes.cardTitleWhite}>
                            Educational informations
                          </h4>
                        </CardHeader>
                        <CardBody>
                          <GridContainer>
                            <GridItem xs={12} sm={12} md={12}>
                              <h4 className={classes.title1}>
                                SSLC - details <Danger>*</Danger>
                              </h4>
                            </GridItem>
                            <GridItem xs={12} sm={12} md={4}>
                              <CustomInput
                                onChange={(e) => HandleData(e)}
                                value={data.SSLCSchool}
                                labelText="Name and Address of School"
                                id="SSLCSchool"
                                formControlProps={{
                                  fullWidth: true,
                                }}
                                inputProps={{
                                  multiline: true,
                                  rows: 5,
                                }}
                              />
                            </GridItem>
                            <GridItem xs={12} sm={12} md={4}>
                              <CustomInput
                                onChange={(e) => HandleData(e)}
                                value={data.SSLCBoard}
                                labelText="Board"
                                id="SSLCBoard"
                                formControlProps={{
                                  fullWidth: true,
                                }}
                              />
                            </GridItem>
                            <GridItem xs={12} sm={12} md={4}>
                              <CustomInput
                                onChange={(e) => HandleData(e)}
                                value={data.RegisterNoSSLC}
                                labelText="Register Number"
                                id="RegisterNoSSLC"
                                formControlProps={{
                                  fullWidth: true,
                                }}
                              />
                            </GridItem>
                            <GridItem xs={12} sm={12} md={4}>
                              <CustomInput
                                onChange={(e) => HandleData(e)}
                                value={data.YearOfPassingSSLC}
                                labelText="Year Of Passing"
                                id="YearOfPassingSSLC"
                                formControlProps={{
                                  fullWidth: true,
                                }}
                              />
                            </GridItem>
                            <GridItem xs={12} sm={12} md={4}>
                              <CustomInput
                                onChange={(e) => HandleData(e)}
                                value={data.SSLCMark}
                                labelText="Overall Mark/ Percentage"
                                id="SSLCMark"
                                formControlProps={{
                                  fullWidth: true,
                                }}
                              />
                            </GridItem>
                            <GridItem xs={12} sm={12} md={4}>
                              <CustomFileInput
                                setFiles={setFiles1}
                                formControlProps={{
                                  fullWidth: true,
                                }}
                                inputProps={{
                                  placeholder:
                                    "Upload SSLC Certificate ( Image File )",
                                }}
                                endButton={{
                                  buttonProps: {
                                    round: true,
                                    color: "primary",
                                    justIcon: true,
                                  },
                                  icon: <AttachFile />,
                                }}
                              />
                              {loading1 ? (
                                <Success>Uploading...</Success>
                              ) : (
                                <>{uploaded1}</>
                              )}
                            </GridItem>
                          </GridContainer>

                          <GridContainer>
                            <GridItem xs={12} sm={12} md={12}>
                              <h4 className={classes.title1}>
                                Higher Secondary - details
                              </h4>
                            </GridItem>
                            <GridItem xs={12} sm={12} md={4}>
                              <CustomInput
                                onChange={(e) => HandleData(e)}
                                value={data.PlusTwoSchool}
                                labelText="Name and Address of School"
                                id="PlusTwoSchool"
                                formControlProps={{
                                  fullWidth: true,
                                }}
                                inputProps={{
                                  multiline: true,
                                  rows: 5,
                                }}
                              />
                            </GridItem>
                            <GridItem xs={12} sm={12} md={4}>
                              <CustomInput
                                onChange={(e) => HandleData(e)}
                                value={data.Board}
                                labelText="Board"
                                id="Board"
                                formControlProps={{
                                  fullWidth: true,
                                }}
                              />
                            </GridItem>
                            <GridItem xs={12} sm={12} md={4}>
                              <CustomInput
                                onChange={(e) => HandleData(e)}
                                value={data.RegisterNoPlusTwo}
                                labelText="Register Number"
                                id="RegisterNoPlusTwo"
                                formControlProps={{
                                  fullWidth: true,
                                }}
                              />
                            </GridItem>
                            <GridItem xs={12} sm={12} md={4}>
                              <CustomInput
                                onChange={(e) => HandleData(e)}
                                value={data.YearOfPassingPlusTwo}
                                labelText="Year Of Passing"
                                id="YearOfPassingPlusTwo"
                                formControlProps={{
                                  fullWidth: true,
                                }}
                              />
                            </GridItem>
                            <GridItem xs={12} sm={12} md={4}>
                              <CustomInput
                                onChange={(e) => HandleData(e)}
                                value={data.PlusTwoMark}
                                labelText="Overall Mark/ Percentage"
                                id="PlusTwoMark"
                                formControlProps={{
                                  fullWidth: true,
                                }}
                              />
                            </GridItem>
                            <GridItem xs={12} sm={12} md={4}>
                              <CustomFileInput
                                setFiles={setFiles}
                                formControlProps={{
                                  fullWidth: true,
                                }}
                                inputProps={{
                                  placeholder:
                                    "Upload Plustwo Certificate ( Image File)",
                                }}
                                endButton={{
                                  buttonProps: {
                                    round: true,
                                    color: "primary",
                                    justIcon: true,
                                  },
                                  icon: <AttachFile />,
                                }}
                              />
                              {loading ? (
                                <Success>Uploading...</Success>
                              ) : (
                                <>{uploaded}</>
                              )}
                            </GridItem>

                            {/* <GridItem xs={12} sm={12} md={4}>
                              <CustomInput
                                onChange={(e) => HandleData(e)}
                                value={data.ExtraActivities}
                                labelText="ExtraActivities"
                                id="ExtraActivities"
                                formControlProps={{
                                  fullWidth: true,
                                }}
                              />
                            </GridItem> */}
                          </GridContainer>
                        </CardBody>{" "}
                        <CardFooter>
                          <GridItem xs={12} sm={12} md={12}>
                            {" "}
                            {invalid ? (
                              <Danger>
                                Please enter all the details to save
                              </Danger>
                            ) : (
                              <></>
                            )}
                          </GridItem>
                          <Button onClick={HandleClear} color="danger">
                            Clear
                          </Button>
                          <Button onClick={HandleNext} color="primary">
                            Next
                          </Button>
                        </CardFooter>
                      </form>
                    </Card>
                  </GridItem>
                </GridContainer>
              )}
              {/* <ProgressionBar /> */}
            </GridItem>
          </GridContainer>

          <hr />
        </div>
        <br />
        <br />
      </div>

      <Footer
        theme="dark"
        content={
          <div>
            <div className={classes.center}>
              Copyright &copy; {1900 + new Date().getYear()}{" "}
              <a href="#" target="_blank" className={classes.aClasses}>
                MaryGiri College,
              </a>{" "}
              All Rights Reserved.
            </div>
          </div>
        }
      >
        <GridContainer>
          <GridItem xs={12} sm={4} md={4}>
            <h5>Contact Address</h5>
            <p>
              Koothattukulam P. O. Ernakulam District, Kerala, India PIN - 686
              662
            </p>
            <p>
              0485 2250079, 8086488844 <br />
              marygiricollege@gmail.com
            </p>
          </GridItem>
          <GridItem xs={12} sm={4} md={4}>
            <h5>Quick Links</h5>
            <div className={classes.socialFeed}>
              <div>
                <a href="#/contact-us">
                  <p>Contact Us</p>
                </a>
              </div>
              <div>
                <a href="#/online-admission">
                  <p>Online Admission</p>
                </a>
              </div>
              <div>
                <p>Online Fee Payment</p>
              </div>
              <div>
                <a href="#/courses-page">
                  {" "}
                  <p>Courses</p>
                </a>
              </div>
            </div>
          </GridItem>
          <GridItem xs={11} sm={2} md={2}>
            <h5>Follow Us on</h5>
            <div className={classes.socialFeed}>
              <Button
                color="facebook"
                round
                justIcon
                href="#https://www.facebook.com/marygiricollege1"
              >
                <i className="fab fa-facebook-f" />
              </Button>
              &nbsp;
              <Button
                color="instagram"
                round
                justIcon
                href="#https://instagram.com/marygiri_college_official?utm_medium=copy_link"
              >
                <i className="fab fa-instagram" />
              </Button>
              &nbsp;
              <Button
                color="twitter"
                round
                justIcon
                href="#https://www.youtube.com/channel/UCEVrpzL3xtWXEwoameOUqUQ"
              >
                <i className="fab fa-youtube" />
              </Button>
            </div>
          </GridItem>
        </GridContainer>
      </Footer>
    </div>
  );
}
