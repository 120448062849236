/* eslint-disable */
import React from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Card from "components/Card/Card.js";

import projectsStyle from "assets/jss/material-kit-pro-react/views/landingPageSections/placementStyle.js";
import classNames from "classnames";
import CardHeader from "components/Card/CardHeader";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { Domain } from "Domain";

const useStyles = makeStyles(projectsStyle);

export default function Faculty({ faculties, setDepartment }) {
  const classes = useStyles();
  //const clasround = useStylesround();
  //data from json file
  console.log("faculties", faculties);
  {
    faculties.map((item, i) => setDepartment(item.departmentname));
  }
  return (
    <div className="cd-section">
      <div className={classes.projects + " " + classes.projects3}>
        <div className={(classes.containerm, classes.containerLimit)}>
          <GridContainer className={classNames(classes.mlAuto, classes.mrAuto)}>
            {faculties.map((item, i) => (
              <GridItem xs={12} sm={6} md={6} key={i}>
                <Card blog className={classes.card1}>
                  <GridContainer>
                    <GridItem xs={12} sm={12} md={5}>
                      <CardHeader image plain>
                        <LazyLoadImage src={Domain + item.image} alt="..." />

                        <div
                          className={classes.coloredShadow}
                          style={{
                            backgroundImage: `url(${Domain + item.image})`,
                            opacity: "1",
                          }}
                        />
                        <div
                          className={classes.coloredShadow}
                          style={{
                            backgroundImage: `url(${Domain + item.image})`,
                            opacity: "1",
                          }}
                        />
                      </CardHeader>
                    </GridItem>
                    <GridItem xs={12} sm={12} md={7}>
                      <GridContainer>
                        <GridItem xs={12} sm={12} md={12}>
                          <div>
                            <h4 className={classes.cardTitle2}>
                              {item.fullname}
                            </h4>
                            <br />
                            <br />
                            <b className={classes.description}>
                              Qualification :{" "}
                            </b>
                            <span className={classes.cardTitle3}>
                              {item.qualifications}
                            </span>
                            <br />

                            <b className={classes.description}>
                              Specialization / Area of Interest :{" "}
                            </b>
                            <span className={classes.cardTitle3}>
                              {item.areaofinterest}
                            </span>
                            <br />
                            {item.Achievements != "." ? (
                              <>
                                <b className={classes.description}>
                                  Achievements :{" "}
                                </b>
                                <span className={classes.cardTitle3}>
                                  {item.achievements}
                                </span>
                              </>
                            ) : (
                              <></>
                            )}
                          </div>
                        </GridItem>
                        <GridItem xs={12} sm={12} md={12}></GridItem>
                      </GridContainer>
                    </GridItem>
                  </GridContainer>
                </Card>
              </GridItem>
            ))}
          </GridContainer>
        </div>
      </div>
    </div>
  );
}
