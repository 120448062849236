import React from "react";
// react components for routing our app without refresh
import { Link } from "react-router-dom";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Card1 from "components/Card/Card1.js";
import Button from "components/CustomButtons/Button.js";
import ArrowRightAltIcon from "@material-ui/icons/ArrowRightAlt";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import { LazyLoadImage } from "react-lazy-load-image-component";

import examplesStyle from "assets/jss/material-kit-pro-react/views/landingPageSections/BeforeFooterStyle.js";
import imagesStyles from "assets/jss/material-kit-pro-react/imagesStyles.js";

import exLanding from "assets/img/gallery/img4.png";
import exBlogPost from "assets/img/gallery/img1.png";
import exProductPage from "assets/img/gallery/img3.png";
import exPricing from "assets/img/gallery/img2.png";

const styles = {
  ...examplesStyle,
  ...imagesStyles,
};

const useStyles = makeStyles(styles);

export default function BeforeFooter() {
  const classes = useStyles();
  return (
    <div className={classes.section}>
      <div className={classes.container}>
        <GridContainer>
          <GridItem md={4} sm={4} xs={12}>
            <Card1
              color="primary"
              className={classes.Card1}
              style={{
                zIndex: 3,
                textAlign: "center",
                marginTop: "12%",
              }}
            >
              <div>
                <h2 className={classes.title} style={{ color: "white" }}>
                  Life @ Marygiri
                </h2>

                <Link to="/gallery-page">
                  <Button color="white" size="lg">
                    View More
                    <ArrowRightAltIcon
                      style={{ marginLeft: "5px", marginRight: "-5px" }}
                    />
                  </Button>
                </Link>
              </div>
            </Card1>

            <Card1 className={classes.imgCardExtended}>
              <Link to={"/"}>
                <LazyLoadImage
                  src={exLanding}
                  alt="..."
                  className={classes.imgCard}
                />
              </Link>
            </Card1>
          </GridItem>
          <GridItem md={3} sm={4} xs={12}>
            <Card1 className={classes.imgCardExtended}>
              <Link to={"/"}>
                <LazyLoadImage
                  src={exBlogPost}
                  alt="..."
                  className={classes.imgCard}
                />
              </Link>
            </Card1>

            <Card1 className={classes.imgCardExtended}>
              <Link to={"/"}>
                <LazyLoadImage
                  src={exProductPage}
                  alt="..."
                  className={classes.imgCard}
                />
              </Link>
            </Card1>
          </GridItem>
          <GridItem md={4} sm={4} xs={12}>
            <Card1 className={classes.imgCardExtended}>
              <Link to={"/"}>
                <LazyLoadImage
                  src={exPricing}
                  alt="..."
                  className={classes.imgCard}
                />
              </Link>
            </Card1>
          </GridItem>
        </GridContainer>
      </div>
    </div>
  );
}
