/* eslint-disable */
import React, { useEffect } from "react";
// nodejs library to set properties for components
import PropTypes from "prop-types";
// react components for routing our app without refresh
import { Link } from "react-router-dom";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import { Domain } from "Domain";

import { Dialog } from "@material-ui/core";
import { Button } from "@material-ui/core";
import Slide from "@material-ui/core/Slide";
// core components
import CustomDropdown from "components/CustomDropdown/CustomDropdown.js";
import Button2 from "components/CustomButtons/Button.js";
import styles from "assets/jss/material-kit-pro-react/components/headerLinksStyle.js";
import { whiteColor } from "assets/jss/material-kit-pro-react";
import PopUpLogin from "views/LoginPage/PopUpLogin";
import axios from "axios";
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});
const useStyles = makeStyles(styles);

export default function HeaderLinks(props) {
  const classes = useStyles();
  const [departments, setDepartments] = React.useState([]);
  const [loginModal, setLoginModal] = React.useState(false);
  const [management, setManagement] = React.useState([]);
  useEffect(() => {
    let passData = {
      PageIndex: 0,
      PageSize: 10,
    };
    axios({
      method: "post",
      url: Domain + "/api/Admin/GetAllManagement.php",
      data: JSON.stringify(passData),
      config: { headers: { "Content-Type": "multipart/form-data" } },
    })
      .then(function (response) {
        console.log("management",response.data)
        setManagement(response.data.Data);
        // if (json.Data.length == 0) setEmpty(true);
      
      })
      .catch(function (response) {
        //handle error
        console.log(response);
      });
    // fetch(Domain + "/api/Admin/GetAllManagement/", {
    //   method: "POST",
    //   headers: {
    //     Accept: "application/json",
    //     "Content-Type": "application/json",
    //   },
    //   body: JSON.stringify(passData),
    // })
    //   .then((response) => response.json())

    //   .then((json) => {
    //     setManagement(json.Data);
    //   });
  }, []);
  const {
    dropdownHoverColor,
    setLoggedin,
    setUserdetails,
    loggedIn,
    userdetails,
  } = props;
  function HandleLogout() {
    setLoggedin(false);
    setLoginModal(false);
  }

  useEffect(() => {
    let passData = {
      PageIndex: 0,
      PageSize: 10,
    };
    axios({
      method: "post",
      url: Domain + "/api/Admin/GetAllDepartments.php",
      data: JSON.stringify(passData),
      config: { headers: { "Content-Type": "multipart/form-data" } },
    })
      .then(function (response) {
        console.log("dep::",response.data.Data)
        setDepartments(response.data.Data);
        // if (json.Data.length == 0) setEmpty(true);
      
      })
      .catch(function (response) {
        //handle error
        console.log(response);
      });
    // fetch(Domain + "/api/Admin/GetAllDepartments/", {
    //   method: "POST",
    //   headers: {
    //     Accept: "application/json",
    //     "Content-Type": "application/json",
    //   },
    //   body: JSON.stringify(passData),
    // })
    //   .then((response) => response.json())

    //   .then((json) => {
    //     setDepartments(json.Data);
    //   });
  }, []);
  const departmentNames = departments.map((d) => ({
    Id: d.id,
    DepartmentName: d.departmentname,
  }));
  console.log("departments:", departments);
  return (
    <List className={classes.list + " " + classes.mlAuto}>
      <ListItem className={classes.listItem}>
        <Link to="/" className={classes.dropdownLink}>
          Home
        </Link>
      </ListItem>
      <ListItem className={classes.listItem}>
        <CustomDropdown
          noLiPadding
          navDropdown
          hoverColor={dropdownHoverColor}
          buttonText="About Us"
          buttonProps={{
            className: classes.navLink,
            color: "transparent",
          }}
          dropdownList={[
            <Link to="/about-us" className={classes.dropdownLink}>
              About Us
            </Link>,
            <Link to="/Mission and Vission-page" className={classes.dropdownLink}>
              Vision and Mission
            </Link>,
            <CustomDropdown
              noLiPadding
              data-ref="multi"
              innerDropDown
              buttonText="Administration"
              buttonProps={{
                className: classes.navLink1,
                simple: true,
                block: true,
              }}
              dropPlacement="right-start"
              dropdownList={[
                <Link
                  replace
                  to={{ pathname: "/administrations-page", state: 1 }}
                  className={classes.dropdownLink}
                >
                  Provincial Administration
                </Link>,
                <Link
                  replace
                  to={{ pathname: "/administrations-page", state: 2 }}
                  className={classes.dropdownLink}
                >
                  Local Administration
                </Link>,
                <Link
                  replace
                  to={{ pathname: "/administrations-page", state: 3 }}
                  className={classes.dropdownLink}
                >
                  Academic Administration
                </Link>,
                <Link
                  replace
                  to={{ pathname: "/administrations-page", state: 4 }}
                  className={classes.dropdownLink}
                >
                  Counseling and health service
                </Link>,
              ]}
            />,

            // <Link to="/faculty-page" className={classes.dropdownLink}>
            //   Faculty
            // </Link>,
            <CustomDropdown
              noLiPadding
              data-ref="multi"
              innerDropDown
              buttonText="Faculty"
              buttonProps={{
                className: classes.navLink1,
                simple: true,
                block: true,
              }}
              dropPlacement="right-start"
              dropdownList={departmentNames.map((dep) => (
                <Link
                  replace
                  to={{ pathname: "/faculty-page", state: dep.Id }}
                  className={classes.dropdownLink}
                >
                  {dep.DepartmentName}
                </Link>
              ))}
            />,
            <CustomDropdown
              noLiPadding
              data-ref="multi"
              innerDropDown
              buttonText="Departments"
              buttonProps={{
                className: classes.navLink1,
                simple: true,
                block: true,
              }}
              dropPlacement="right-start"
              dropdownList={departmentNames.map((dep) => (
                <Link
                  replace
                  to={{ pathname: "/department", state: dep.Id }}
                  className={classes.dropdownLink}
                >
                  {dep.DepartmentName}
                </Link>
              ))}
            />,
            <CustomDropdown
              noLiPadding
              data-ref="multi"
              innerDropDown
              buttonText="Messages"
              buttonProps={{
                className: classes.navLink1,
                simple: true,
                block: true,
              }}
              dropPlacement="right-start"
              dropdownList={management.map((item) => (
                <Link
                  replace
                  to={{ pathname: "/messages-page", state: item.id }}
                  className={classes.dropdownLink}
                >
                  {item.post}
                </Link>
              ))}
            />,
            <CustomDropdown
              noLiPadding
              data-ref="multi"
              innerDropDown
              buttonText="Facilities"
              buttonProps={{
                className: classes.navLink1,
                simple: true,
                block: true,
              }}
              dropPlacement="right-start"
              dropdownList={[
                <Link to="/Office-page" className={classes.dropdownLink}>
                  Office
                </Link>,
                <Link to="/Library-page" className={classes.dropdownLink}>
                  Library
                </Link>,
                <Link to="/Computer-lab" className={classes.dropdownLink}>
                  Computer Lab
                </Link>,
              ]}
            />,
            <Link to="/publications-page" className={classes.dropdownLink}>
              Publications
            </Link>,
            <CustomDropdown
              noLiPadding
              data-ref="multi"
              innerDropDown
              buttonText="Clubs"
              buttonProps={{
                className: classes.navLink1,
                simple: true,
                block: true,
              }}
              dropPlacement="right-start"
              dropdownList={[
                <Link to="/club-CSM" className={classes.dropdownLink}>
                  Catholic Student Movement
                </Link>,
                <Link to="/club-NSS" className={classes.dropdownLink}>
                  NSS
                </Link>,
                <Link to="/club-Womencell" className={classes.dropdownLink}>
                  Women Cell
                </Link>,
              ]}
            />,
          ]}
        />
      </ListItem>
      <ListItem className={classes.listItem}>
        <CustomDropdown
          noLiPadding
          navDropdown
          hoverColor={dropdownHoverColor}
          buttonText="Academics"
          buttonProps={{
            className: classes.navLink,
            simple: true,
            block: true,
          }}
          dropdownList={[
            <Link to="/courses-page" className={classes.dropdownLink}>
              UG Courses
            </Link>,
            <Link to="/error-page" className={classes.dropdownLink}>
              Add-on Courses
            </Link>,
            <Link to="/error-page" className={classes.dropdownLink}>
              Proposed courses
            </Link>,
            <CustomDropdown
              noLiPadding
              data-ref="multi"
              innerDropDown
              buttonText="Examination"
              buttonProps={{
                className: classes.navLink1,
                simple: true,
                block: true,
              }}
              dropPlacement="right-start"
              dropdownList={[
                <Link to="/error-page" className={classes.dropdownLink}>
                  University
                </Link>,
                <Link to="/error-page" className={classes.dropdownLink}>
                  Model
                </Link>,
                <Link to="/error-page" className={classes.dropdownLink}>
                  Internal
                </Link>,
              ]}
            />,
            <CustomDropdown
              noLiPadding
              data-ref="multi"
              innerDropDown
              buttonText="Result"
              buttonProps={{
                className: classes.navLink1,
                simple: true,
                block: true,
              }}
              dropPlacement="right-start"
              dropdownList={[
                <Link to="/error-page" className={classes.dropdownLink}>
                  University
                </Link>,
                <Link to="/error-page" className={classes.dropdownLink}>
                  Internal
                </Link>,
              ]}
            />,
            <CustomDropdown
              noLiPadding
              data-ref="multi"
              innerDropDown
              buttonText="Time Table"
              buttonProps={{
                className: classes.navLink1,
                simple: true,
                block: true,
              }}
              dropPlacement="right-start"
              dropdownList={[
                <Link to="/error-page" className={classes.dropdownLink}>
                  Internal/Model
                </Link>,
                <Link to="/error-page" className={classes.dropdownLink}>
                  University
                </Link>,
              ]}
            />,
          ]}
        />
      </ListItem>
      <ListItem className={classes.listItem}>
        <CustomDropdown
          noLiPadding
          navDropdown
          hoverColor={dropdownHoverColor}
          buttonText="Admissions"
          buttonProps={{
            className: classes.navLink,
            simple: true,
            block: true,
          }}
          dropdownList={[
            <Link to="/error-page" className={classes.dropdownLink}>
              Prospectus
            </Link>,
            <Link to="/online-admission" className={classes.dropdownLink}>
              Online Admission
            </Link>,
          ]}
        />
      </ListItem>
      <ListItem className={classes.listItem}>
        <Link to="/placements-page" className={classes.dropdownLink}>
          Placements
        </Link>
      </ListItem>

      <ListItem className={classes.listItem}>
        <CustomDropdown
          noLiPadding
          navDropdown
          hoverColor={dropdownHoverColor}
          buttonText="IQAC"
          buttonProps={{
            className: classes.navLink,
            simple: true,
            block: true,
          }}
          dropdownList={[
            <Link to="/error-page" className={classes.dropdownLink}>
              About IQAC
            </Link>,
            <Link to="/error-page" className={classes.dropdownLink}>
              Goals and Functions
            </Link>,
            <Link to="/error-page" className={classes.dropdownLink}>
              IQAC Composition
            </Link>,
            <Link to="/error-page" className={classes.dropdownLink}>
              AISHE Certificates
            </Link>,
            <Link to="/error-page" className={classes.dropdownLink}>
              IQAC Minutes
            </Link>,
            <Link to="/error-page" className={classes.dropdownLink}>
              IQAC Reports
            </Link>,
          ]}
        />
      </ListItem>
      <ListItem className={classes.listItem}>
        <Link to="/contact-us" className={classes.dropdownLink}>
          Contact Us
        </Link>
      </ListItem>
      {loggedIn ? (
        <ListItem className={classes.listItem}>
          <CustomDropdown
            noLiPadding
            left
            caret={false}
            hoverColor="primary"
            dropdownHeader={userdetails.FullName}
            buttonText={
              <img
                src={Domain + userdetails.Image}
                className={classes.img}
                alt="profile"
              />
            }
            buttonProps={{
              className: classes.navLink1 + " " + classes.imageDropdownButton,
              simple: true,
              block: true,
            }}
            dropdownList={[
              <Link
                to={{
                  pathname: "/profile-page",
                  prop: userdetails,
                  setLoggedin: setLoggedin,
                }}
                className={classes.dropdownLink}
              >
                My profile
              </Link>,

              <Link onClick={HandleLogout} className={classes.dropdownLink}>
                Sign out
              </Link>,
            ]}
          />
        </ListItem>
      ) : (
        <ListItem className={classes.listItem}>
          <Button
            variant="outlined"
            size="large"
            onClick={() => setLoginModal(true)}
            style={{
              borderColor: whiteColor,
              color: whiteColor,
              marginRight: "10px",
            }}
          >
            Login
          </Button>
          <Dialog
            classes={{
              root: classes.modalRoot,
              paper: classes.modal + " " + classes.modalLogin,
            }}
            open={loginModal}
            TransitionComponent={Transition}
            keepMounted
            onClose={() => setLoginModal(false)}
            aria-labelledby="login-modal-slide-title"
            aria-describedby="login-modal-slide-description"
          >
            <PopUpLogin
              setLoginModal={setLoginModal}
              setLoggedin={setLoggedin}
              setUserdetails={setUserdetails}
            />
          </Dialog>
        </ListItem>
      )}
      {!loggedIn ? (
        <ListItem className={classes.listItem}>
          <Link to="/online-admission">
            <Button2
              className={classes.navButton}
              color={window.innerWidth < 960 ? "primary" : "white"}
            >
              Online Admission
            </Button2>
          </Link>
        </ListItem>
      ) : (
        <></>
      )}
    </List>
  );
}

HeaderLinks.defaultProps = {
  hoverColor: "primary",
};

HeaderLinks.propTypes = {
  dropdownHoverColor: PropTypes.oneOf([
    "dark",
    "primary",
    "info",
    "success",
    "warning",
    "danger",
    "rose",
  ]),
};
