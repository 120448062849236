/*eslint-disable*/
import React, { useState } from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// core components
import Header from "components/Header/Header.js";
import HeaderLinks2 from "components/Header/HeaderLinks2.js";
import Parallax from "components/Parallax/Parallax.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Footer from "components/Footer/Footer.js";
import Button from "components/CustomButtons/Button.js";
import Paginations from "components/Pagination/Pagination.js";

import CoursesStyle from "assets/jss/material-kit-pro-react/views/CoursesStyle.js";
import EventSection from "views/LandingPage/Sections/Events/EventSection";

const useStyles = makeStyles(CoursesStyle);

export default function EventsPage() {
  const [index, setIndex] = useState(0);
  function HandleNext() {
    setIndex(index + 1);
  }
  function HandlePrev() {
    if (index > 0) setIndex(index - 1);
  }
  React.useEffect(() => {
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
  });
  const classes = useStyles();
  return (
    <div>
      <Parallax image={require("assets/img/admissionsBg.webp")} small>
        <div className={classes.container}>
          <GridContainer>
            <GridItem
              md={8}
              sm={8}
              lg={12}
              className={classNames(classes.mlAuto, classes.mrAuto)}
            >
              <p className={classes.title}>Home/Events</p>
              <h1 className={classes.title}>Up coming Events</h1>
            </GridItem>
          </GridContainer>
        </div>
      </Parallax>
      <div className={classes.main}>
        <div className={classes.container}>
          <EventSection size={10} index={index} landingpage={false} />
          {/* <Paginations
            HandlePrev={HandlePrev}
            HandleNext={HandleNext}
            setIndex={setIndex}
            className={classes.textCenter + " " + classes.justifyContentCenter}
            pages={[
              { text: "< Prev" },
              { active: true, text: 1 },
              { text: 2 },
              { text: 3 },
              { text: 4 },
              { text: 5 },
              { text: "Next >" },
            ]}
            color="primary"
          /> */}
        </div>
        <br />
        <br />
      </div>

      <Footer
        theme="dark"
        content={
          <div>
            <div className={classes.center}>
              Copyright &copy; {1900 + new Date().getYear()}{" "}
              <a href="#" target="_blank" className={classes.aClasses}>
                MaryGiri College,
              </a>{" "}
              All Rights Reserved.
            </div>
          </div>
        }
      >
        <GridContainer>
          <GridItem xs={12} sm={4} md={4}>
            <h5>Contact Address</h5>
            <p>
              Koothattukulam P. O. Ernakulam District, Kerala, India PIN - 686
              662
            </p>
            <p>
              0485 2250079, 8086488844 <br />
              marygiricollege@gmail.com
            </p>
          </GridItem>
          <GridItem xs={12} sm={4} md={4}>
            <h5>Quick Links</h5>
            <div className={classes.socialFeed}>
              <div>
                <a href="#/contact-us">
                  <p>Contact Us</p>
                </a>
              </div>
              <div>
                <a href="#/online-admission">
                  <p>Online Admission</p>
                </a>
              </div>
              <div>
                <p>Online Fee Payment</p>
              </div>
              <div>
                <a href="#/courses-page">
                  {" "}
                  <p>Courses</p>
                </a>
              </div>
            </div>
          </GridItem>
          <GridItem xs={11} sm={2} md={2}>
            <h5>Follow Us on</h5>
            <div className={classes.socialFeed}>
              <Button
                color="facebook"
                round
                justIcon
                href="#https://www.facebook.com/marygiricollege1"
              >
                <i className="fab fa-facebook-f" />
              </Button>
              &nbsp;
              <Button
                color="instagram"
                round
                justIcon
                href="#https://instagram.com/marygiri_college_official?utm_medium=copy_link"
              >
                <i className="fab fa-instagram" />
              </Button>
              &nbsp;
              <Button
                color="twitter"
                round
                justIcon
                href="#https://www.youtube.com/channel/UCEVrpzL3xtWXEwoameOUqUQ"
              >
                <i className="fab fa-youtube" />
              </Button>
            </div>
          </GridItem>
        </GridContainer>
      </Footer>
    </div>
  );
}
