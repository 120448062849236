/* eslint-disable */
import React from "react";
// nodejs library to set properties for components
import PropTypes from "prop-types";
// react components for routing our app without refresh
import { Link } from "react-router-dom";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import Icon from "@material-ui/core/Icon";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import { Dialog } from "@material-ui/core";
import { Button } from "@material-ui/core";
import Slide from "@material-ui/core/Slide";
import Close from "@material-ui/icons/Close";
import Mail from "@material-ui/icons/Mail";
import InputAdornment from "@material-ui/core/InputAdornment";
import Face from "@material-ui/icons/Face";
// core components
import CustomDropdown from "components/CustomDropdown/CustomDropdown.js";
import Button2 from "components/CustomButtons/Button.js";
import styles from "assets/jss/material-kit-pro-react/components/headerLinksStyle.js";
import { whiteColor, primaryColor } from "assets/jss/material-kit-pro-react";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});
const useStyles = makeStyles(styles);

export default function HeaderLinks(props) {
  const [loginModal, setLoginModal] = React.useState(false);
  const easeInOutQuad = (t, b, c, d) => {
    t /= d / 2;
    if (t < 1) return (c / 2) * t * t + b;
    t--;
    return (-c / 2) * (t * (t - 2) - 1) + b;
  };

  const smoothScroll = (e, target) => {
    if (window.location.pathname === "/sections") {
      var isMobile = navigator.userAgent.match(
        /(iPad)|(iPhone)|(iPod)|(android)|(webOS)/i
      );
      if (isMobile) {
        // if we are on mobile device the scroll into view will be managed by the browser
      } else {
        e.preventDefault();
        var targetScroll = document.getElementById(target);
        scrollGo(document.documentElement, targetScroll.offsetTop, 1250);
      }
    }
  };
  const scrollGo = (element, to, duration) => {
    var start = element.scrollTop,
      change = to - start,
      currentTime = 0,
      increment = 20;

    var animateScroll = function () {
      currentTime += increment;
      var val = easeInOutQuad(currentTime, start, change, duration);
      element.scrollTop = val;
      if (currentTime < duration) {
        setTimeout(animateScroll, increment);
      }
    };
    animateScroll();
  };
  var onClickSections = {};

  const { dropdownHoverColor } = props;
  const classes = useStyles();
  return (
    <List className={classes.list + " " + classes.mlAuto}>
      <ListItem className={classes.listItem}>
        <Link to="/" className={classes.dropdownLink}>
          Home
        </Link>
      </ListItem>
      <ListItem className={classes.listItem}>
        <CustomDropdown
          noLiPadding
          navDropdown
          hoverColor={dropdownHoverColor}
          buttonText="About Us"
          buttonProps={{
            className: classes.navLink,
            color: "transparent",
          }}
          dropdownList={[
            <Link to="/about-us" className={classes.dropdownLink}>
              About Us
            </Link>,
            <Link to="/error-page" className={classes.dropdownLink}>
              Vision and Mission
            </Link>,
            <CustomDropdown
              noLiPadding
              data-ref="multi"
              innerDropDown
              buttonText="Administration"
              buttonProps={{
                className: classes.navLink1,
                color: "transparent",
              }}
              dropPlacement="right-start"
              dropdownList={[
                <Link to="/error-page" className={classes.dropdownLink}>
                  Provincial Administration
                </Link>,
                <Link to="/error-page" className={classes.dropdownLink}>
                  Local Administration
                </Link>,
                <Link to="/error-page" className={classes.dropdownLink}>
                  Academic Administration
                </Link>,
                <Link to="/error-page" className={classes.dropdownLink}>
                  Counseling and health service
                </Link>,
              ]}
            />,

            <Link to="/faculty-page" className={classes.dropdownLink}>
              Faculty
            </Link>,
            <CustomDropdown
              noLiPadding
              data-ref="multi"
              innerDropDown
              buttonText="Departments"
              buttonProps={{
                className: classes.navLink1,
                color: "transparent",
              }}
              dropPlacement="right-start"
              dropdownList={[
                <Link
                  to="/commerce-department"
                  className={classes.dropdownLink}
                >
                  Department of Commerce and Management
                </Link>,
                <Link
                  to="/computer-department"
                  className={classes.dropdownLink}
                >
                  Department of Computer Applications
                </Link>,
                <Link to="/english-department" className={classes.dropdownLink}>
                  Department Of English Language and Literature
                </Link>,
              ]}
            />,
            <CustomDropdown
              noLiPadding
              data-ref="multi"
              innerDropDown
              buttonText="Messages"
              buttonProps={{
                className: classes.navLink1,
                color: "transparent",
              }}
              dropPlacement="right-start"
              dropdownList={[
                <Link to="/error-page" className={classes.dropdownLink}>
                  Director
                </Link>,
                <Link to="/error-page" className={classes.dropdownLink}>
                  Administrator
                </Link>,
                <Link to="/error-page" className={classes.dropdownLink}>
                  Principal
                </Link>,
              ]}
            />,
            <CustomDropdown
              noLiPadding
              data-ref="multi"
              innerDropDown
              buttonText="Facilities"
              buttonProps={{
                className: classes.navLink1,
                color: "transparent",
              }}
              dropPlacement="right-start"
              dropdownList={[
                <Link to="/Office-page" className={classes.dropdownLink}>
                  Office
                </Link>,
                <Link to="/Library-page" className={classes.dropdownLink}>
                  Library
                </Link>,
                <Link to="/Computer-lab" className={classes.dropdownLink}>
                  Computer Lab
                </Link>,
              ]}
            />,
            <Link to="/error-page" className={classes.dropdownLink}>
              Publications
            </Link>,
            <CustomDropdown
              noLiPadding
              data-ref="multi"
              innerDropDown
              buttonText="Clubs"
              buttonProps={{
                className: classes.navLink1,
                color: "transparent",
              }}
              dropPlacement="right-start"
              dropdownList={[
                <Link to="/club-CSM" className={classes.dropdownLink}>
                  Catholic Student Movement
                </Link>,
                <Link to="/club-NSS" className={classes.dropdownLink}>
                  NSS
                </Link>,
                <Link to="/club-Womencell" className={classes.dropdownLink}>
                  Women Cell
                </Link>,
              ]}
            />,
          ]}
        />
      </ListItem>
      <ListItem className={classes.listItem}>
        <CustomDropdown
          noLiPadding
          navDropdown
          hoverColor={dropdownHoverColor}
          buttonText="Academics"
          buttonProps={{
            className: classes.navLink,
            color: "transparent",
          }}
          dropdownList={[
            <Link to="/courses-page" className={classes.dropdownLink}>
              UG Courses
            </Link>,
            <Link to="/error-page" className={classes.dropdownLink}>
              Add-on Courses
            </Link>,
            <Link to="/error-page" className={classes.dropdownLink}>
              Proposed courses
            </Link>,
            <CustomDropdown
              noLiPadding
              data-ref="multi"
              innerDropDown
              buttonText="Examination"
              buttonProps={{
                className: classes.navLink1,
                color: "transparent",
              }}
              dropPlacement="right-start"
              dropdownList={[
                <Link to="/error-page" className={classes.dropdownLink}>
                  University
                </Link>,
                <Link to="/error-page" className={classes.dropdownLink}>
                  Model
                </Link>,
                <Link to="/error-page" className={classes.dropdownLink}>
                  Internal
                </Link>,
              ]}
            />,
            <CustomDropdown
              noLiPadding
              data-ref="multi"
              innerDropDown
              buttonText="Result"
              buttonProps={{
                className: classes.navLink1,
                color: "transparent",
              }}
              dropPlacement="right-start"
              dropdownList={[
                <Link to="/error-page" className={classes.dropdownLink}>
                  University
                </Link>,
                <Link to="/error-page" className={classes.dropdownLink}>
                  Internal
                </Link>,
              ]}
            />,
            <CustomDropdown
              noLiPadding
              data-ref="multi"
              innerDropDown
              buttonText="Time Table"
              buttonProps={{
                className: classes.navLink1,
                color: "transparent",
              }}
              dropPlacement="right-start"
              dropdownList={[
                <Link to="/error-page" className={classes.dropdownLink}>
                  Internal/Model
                </Link>,
                <Link to="/error-page" className={classes.dropdownLink}>
                  University
                </Link>,
              ]}
            />,
          ]}
        />
      </ListItem>
      <ListItem className={classes.listItem}>
        <CustomDropdown
          noLiPadding
          navDropdown
          hoverColor={dropdownHoverColor}
          buttonText="Admissions"
          buttonProps={{
            className: classes.navLink,
            color: "transparent",
          }}
          dropdownList={[
            <Link to="/error-page" className={classes.dropdownLink}>
              Prospectus
            </Link>,
            <Link to="/admissions-page" className={classes.dropdownLink}>
              Online Admission
            </Link>,
          ]}
        />
      </ListItem>
      <ListItem className={classes.listItem}>
        <Link to="/placements-page" className={classes.dropdownLink}>
          Placements
        </Link>
      </ListItem>

      <ListItem className={classes.listItem}>
        <CustomDropdown
          noLiPadding
          navDropdown
          hoverColor={dropdownHoverColor}
          buttonText="IQAC"
          buttonProps={{
            className: classes.navLink,
            color: "transparent",
          }}
          dropdownList={[
            <Link to="/error-page" className={classes.dropdownLink}>
              About IQAC
            </Link>,
            <Link to="/error-page" className={classes.dropdownLink}>
              Goals and Functions
            </Link>,
            <Link to="/error-page" className={classes.dropdownLink}>
              IQAC Composition
            </Link>,
            <Link to="/error-page" className={classes.dropdownLink}>
              AISHE Certificates
            </Link>,
            <Link to="/error-page" className={classes.dropdownLink}>
              IQAC Minutes
            </Link>,
            <Link to="/error-page" className={classes.dropdownLink}>
              IQAC Reports
            </Link>,
          ]}
        />
      </ListItem>
      <ListItem className={classes.listItem}>
        <Link to="/contact-us" className={classes.dropdownLink}>
          Contact Us
        </Link>
      </ListItem>

      <ListItem className={classes.listItem}>
        <Button
          variant="outlined"
          size="large"
          onClick={() => setLoginModal(true)}
          style={{
            borderColor: whiteColor,
            color: whiteColor,
            marginRight: "10px",
          }}
        >
          Login
        </Button>
        <Dialog
          classes={{
            root: classes.modalRoot,
            paper: classes.modal + " " + classes.modalLogin,
          }}
          open={loginModal}
          TransitionComponent={Transition}
          keepMounted
          onClose={() => setLoginModal(false)}
          aria-labelledby="login-modal-slide-title"
          aria-describedby="login-modal-slide-description"
        >
          <Card plain className={classes.modalLoginCard}>
            <DialogTitle
              id="login-modal-slide-title"
              disableTypography
              className={classes.modalHeader}
            >
              <CardHeader
                plain
                color="primary"
                className={classes.textCenter + " " + classes.cardLoginHeader}
              >
                <Button2
                  simple
                  className={classes.modalCloseButton}
                  key="close"
                  aria-label="Close"
                  onClick={() => setLoginModal(false)}
                >
                  {" "}
                  <Close className={classes.modalClose} />
                </Button2>
                <h5 className={classes.cardTitleWhite}>Log in</h5>
                <div className={classes.socialLine}>
                  <Button2 justIcon link className={classes.socialLineButton}>
                    <i className="fab fa-facebook-square" />
                  </Button2>
                  <Button2 justIcon link className={classes.socialLineButton}>
                    <i className="fab fa-twitter" />
                  </Button2>
                  <Button2 justIcon link className={classes.socialLineButton}>
                    <i className="fab fa-google-plus-g" />
                  </Button2>
                </div>
              </CardHeader>
            </DialogTitle>
            <DialogContent
              id="login-modal-slide-description"
              className={classes.modalBody}
            >
              <form>
                <p className={classes.description + " " + classes.textCenter}>
                  Or Be Classical
                </p>
                <CardBody className={classes.cardLoginBody}>
                  <CustomInput
                    id="login-modal-first"
                    formControlProps={{
                      fullWidth: true,
                    }}
                    inputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <Face className={classes.icon} />
                        </InputAdornment>
                      ),
                      placeholder: "First Name...",
                    }}
                  />
                  <CustomInput
                    id="login-modal-email"
                    formControlProps={{
                      fullWidth: true,
                    }}
                    inputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <Mail className={classes.icon} />
                        </InputAdornment>
                      ),
                      placeholder: "Email...",
                    }}
                  />
                  <CustomInput
                    id="login-modal-pass"
                    formControlProps={{
                      fullWidth: true,
                    }}
                    inputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <Icon className={classes.icon}>lock_outline</Icon>
                        </InputAdornment>
                      ),
                      placeholder: "Password...",
                    }}
                  />
                </CardBody>
              </form>
            </DialogContent>
            <DialogActions
              className={
                classes.modalFooter + " " + classes.justifyContentCenter
              }
            >
              <Button2 color="primary" size="lg">
                Get started
              </Button2>
            </DialogActions>
          </Card>
        </Dialog>
      </ListItem>
      <ListItem className={classes.listItem}>
        <Button2
          href="#/online-admission"
          className={classes.navButton}
          color={window.innerWidth < 960 ? "primary" : "white"}
        >
          Online Admission
        </Button2>
      </ListItem>
    </List>
  );
}

HeaderLinks.defaultProps = {
  hoverColor: "primary",
};

HeaderLinks.propTypes = {
  dropdownHoverColor: PropTypes.oneOf([
    "dark",
    "primary",
    "info",
    "success",
    "warning",
    "danger",
    "rose",
  ]),
};
