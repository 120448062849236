import React from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import ArrowRightAltIcon from "@material-ui/icons/ArrowRightAlt";

import pricingStyle from "assets/jss/material-kit-pro-react/views/landingPageSections/AboutUsStyle.js";

import bg1 from "assets/img/sectionBg/bg1.jpg";
import ScrollAnimation from "react-animate-on-scroll";

const useStyles = makeStyles(pricingStyle);

export default function SectionAboutUs({ ...rest }) {
  const classes = useStyles();
  return (
    <div className="cd-section" {...rest}>
      {/* Pricing 1 START */}
      <div
        className={
          classes.pricing + " " + classes.pricing1 + " " + classes.section
        }
        style={{ backgroundImage: `url(${bg1})` }}
      >
        <div className={classes.container}>
          <GridContainer>
            <GridItem
              xs={12}
              sm={6}
              md={6}
              className={classes.mlAuto + " " + classes.mrAuto}
            >
              <ScrollAnimation animateIn="animate__fadeIn">
                <h2 className={classes.title}>About Us</h2>
                <h5 className={classes.description}>
                  Marygiri College of Arts and Science, Koothattukulam is an
                  institution managed by CMI Fathers of St.Joseph Province,
                  Kottayam. This institution is an embodiment of the dreams and
                  visions of Saint Kuriakose Elias Chavara,the greatest
                  educational visionary of the 19th century and the founder of
                  the CMI congregation.
                </h5>
                <Button color="primary" size="lg" href="#/about-us">
                  Explore More
                  <ArrowRightAltIcon
                    style={{ marginLeft: "20px", marginRight: "-20px" }}
                  />
                </Button>
                <div className={classes.sectionSpace} />
              </ScrollAnimation>
            </GridItem>
            <GridItem
              xs={12}
              sm={6}
              md={6}
              className={
                classes.mlAuto + " " + classes.mrAuto + " " + classes.textCenter
              }
            ></GridItem>
          </GridContainer>
        </div>
      </div>
      {/* Pricing 1 END */}
    </div>
  );
}
