/*eslint-disable*/
import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// core components
import Header from "components/Header/Header.js";
import HeaderLinks2 from "components/Header/HeaderLinks2.js";
import Parallax from "components/Parallax/Parallax.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Footer from "components/Footer/Footer.js";
import Button from "components/CustomButtons/Button.js";
import Publications from "./Publications";
// sections for this page

import CoursesStyle from "assets/jss/material-kit-pro-react/views/CoursesStyle.js";
import { Link } from "react-router-dom";

const useStyles = makeStyles(CoursesStyle);

export default function PublicationsPage() {
  const [specialitySelect, setSpecialitySelect] = React.useState("0");
  const handleSpeciality = (event) => {
    setSpecialitySelect(event.target.value);
  };
  React.useEffect(() => {
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
  });
  const classes = useStyles();
  return (
    <div>
      <Parallax image={require("assets/img/admissionsBg.webp")} small>
        <div className={classes.container}>
          <GridContainer>
            <GridItem
              md={8}
              sm={8}
              lg={12}
              className={classNames(classes.mlAuto, classes.mrAuto)}
            >
              <p className={classes.title}>Home/Publications</p>
              <h1 className={classes.title}>Publications</h1>
            </GridItem>
          </GridContainer>
        </div>
      </Parallax>
      <div className={classes.main}>
        <div className={classes.container}>
          <GridContainer className={classNames(classes.mlAuto, classes.mrAuto)}>
            <GridItem xs={12} sm={12} md={12}>
              <p className={classes.description}>
                Marygiri College of Arts and Science, Koothattukulam is an
                institution managed by CMI Fathers of St.Joseph Province,
                Kottayam. This institution is an embodiment of the dreams and
                visions of Saint Kuriakose Elias Chavara,the greatest
                educational visionary of the 19th century and the founder of the
                CMI congregation{" "}
              </p>
            </GridItem>
          </GridContainer>

          <hr />
          <GridContainer
            className={classNames(
              classes.mlAuto,
              classes.mrAuto,
              classes.textCenter
            )}
          >
            <GridItem xs={12} sm={12} md={12}>
              <Publications />
              click Here to download{" "}
              <Link to="https://drive.google.com/file/d/19NfFC9SbjXDBcFFau6_HjjvUMa0yZSp-/view?usp=sharing">
                {" "}
                Report of the College for the year 2019-20
              </Link>
            </GridItem>
            <GridItem xs={12} sm={12} md={12}>
              click Here to download{" "}
              <Link to=""> Report of the College for the year 2018-19</Link>
            </GridItem>
            <GridItem xs={12} sm={12} md={12}>
              click Here to download{" "}
              <Link to=""> Report of the College for the year 2017-18</Link>
            </GridItem>
          </GridContainer>
        </div>
        <br />
        <br />
      </div>

      <Footer
        theme="dark"
        content={
          <div>
            <div className={classes.center}>
              Copyright &copy; {1900 + new Date().getYear()}{" "}
              <a href="#" target="_blank" className={classes.aClasses}>
                MaryGiri College,
              </a>{" "}
              All Rights Reserved.
            </div>
          </div>
        }
      >
        <GridContainer>
          <GridItem xs={12} sm={4} md={4}>
            <h5>Contact Address</h5>
            <p>
              Koothattukulam P. O. Ernakulam District, Kerala, India PIN - 686
              662
            </p>
            <p>
              0485 2250079, 8086488844 <br />
              marygiricollege@gmail.com
            </p>
          </GridItem>
          <GridItem xs={12} sm={4} md={4}>
            <h5>Quick Links</h5>
            <div className={classes.socialFeed}>
              <div>
                <a href="#/contact-us">
                  <p>Contact Us</p>
                </a>
              </div>
              <div>
                <a href="#/online-admission">
                  <p>Online Admission</p>
                </a>
              </div>
              <div>
                <p>Online Fee Payment</p>
              </div>
              <div>
                <a href="#/courses-page">
                  {" "}
                  <p>Courses</p>
                </a>
              </div>
            </div>
          </GridItem>
          <GridItem xs={11} sm={2} md={2}>
            <h5>Follow Us on</h5>
            <div className={classes.socialFeed}>
              <Button
                color="facebook"
                round
                justIcon
                href="#https://www.facebook.com/marygiricollege1"
              >
                <i className="fab fa-facebook-f" />
              </Button>
              &nbsp;
              <Button
                color="instagram"
                round
                justIcon
                href="#https://instagram.com/marygiri_college_official?utm_medium=copy_link"
              >
                <i className="fab fa-instagram" />
              </Button>
              &nbsp;
              <Button
                color="twitter"
                round
                justIcon
                href="#https://www.youtube.com/channel/UCEVrpzL3xtWXEwoameOUqUQ"
              >
                <i className="fab fa-youtube" />
              </Button>
            </div>
          </GridItem>
        </GridContainer>
      </Footer>
    </div>
  );
}
