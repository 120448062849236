/*eslint-disable*/
import React, { useState } from "react";
// @material-ui/core components
import { Domain } from "Domain";
import { makeStyles } from "@material-ui/core/styles";
// @material-ui/icons
import PinDrop from "@material-ui/icons/PinDrop";
import Phone from "@material-ui/icons/Phone";
import BusinessCenter from "@material-ui/icons/BusinessCenter";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import InfoArea from "components/InfoArea/InfoArea.js";
import CustomInput from "components/CustomInput/CustomInputFiled.js";
import Button from "components/CustomButtons/Button.js";

import contactUsStyle from "assets/jss/material-kit-pro-react/views/contactUsStyle.js";
import { Snackbar } from "@material-ui/core";
import { AddAlert } from "@material-ui/icons";
import Danger from "components/Typography/Danger";
const useStyles = makeStyles(contactUsStyle);

export default function ContactUs() {
  const classes = useStyles();
  const [validated, setValidated] = useState(true);
  const [saved, setSaved] = useState(false);
  //Saved Notification trigger
  const showSavedNotification = () => {
    if (!saved) {
      setSaved(true);
      setTimeout(function () {
        setSaved(false);
      }, 3000);
    }
  };
  const [data, setData] = useState({
    Id: 0,
    Name: "",
    Mobile: "",
    Description: "",
    Date: "",
    Status: "Created",
  });
  //Function to handle Data input
  function HandleData(e) {
    const newData = { ...data };
    newData[e.target.id] = e.target.value;
    setData(newData);
    console.log(newData);
  }
  //Function for Validating fields
  function ValidateFields() {
    if (data.Name == "") {
      return false;
    } else if (data.Mobile == "") {
      return false;
    } else if (data.Date == "") {
      return false;
    } else if (data.Description == "") {
      return false;
    } else return true;
  }
  //Function to save Data
  function HandleSave() {
    if (ValidateFields()) {
      setValidated(true);
      fetch(Domain + "/api/Admin/CreateEnquiry/", {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      })
        .then((response) => response.json())

        .then((json) => {
          console.log(json);
          if (json.Success) {
            setData({
              Id: 0,
              Name: "",
              Mobile: "",
              Description: "",
              Date: "",
              Status: "Created",
            });
            showSavedNotification();
          } else {
            console.log("Error in insertion");
            console.log(json);
          }
        });
    } else {
      setValidated(false);
    }
  }
  return (
    <div>
      <Snackbar
        place="bc"
        color="success"
        icon={AddAlert}
        message="Enquiry Saved Successfully"
        open={saved}
        closeNotification={() => setSaved(false)}
        close
      />
      <div className={classes.contactContent}>
        <div className={classes.container}>
          <h2 className={classes.title}>Send us a message</h2>

          <GridContainer>
            <GridItem md={6} sm={6}>
              <p>
                You can contact us with anything related to our Services. We
                {"'"}ll get in touch with you as soon as possible.
                <br />
                <br />
              </p>
              <form>
                <CustomInput
                  onChange={(e) => HandleData(e)}
                  value={data.Name}
                  labelText="Your Name"
                  id="Name"
                  formControlProps={{
                    fullWidth: true,
                  }}
                />
                <CustomInput
                  onChange={(e) => HandleData(e)}
                  value={data.Mobile}
                  labelText="Mobile"
                  id="Mobile"
                  formControlProps={{
                    fullWidth: true,
                  }}
                />
                <CustomInput
                  onChange={(e) => HandleData(e)}
                  value={data.Date}
                  labelText="Date"
                  id="Date"
                  formControlProps={{
                    fullWidth: true,
                  }}
                />
                <CustomInput
                  onChange={(e) => HandleData(e)}
                  value={data.Description}
                  labelText="Enter the Message.."
                  id="Description"
                  formControlProps={{
                    fullWidth: true,
                  }}
                  inputProps={{
                    multiline: true,
                    rows: 6,
                  }}
                />
                {validated ? (
                  <p></p>
                ) : (
                  <Danger>
                    {" "}
                    <p>Please fill the contact form with proper data</p>
                  </Danger>
                )}
                <div className={classes.textCenter}>
                  <Button color="primary" round onClick={HandleSave}>
                    Send Message
                  </Button>
                </div>{" "}
              </form>
            </GridItem>

            <GridItem md={5} sm={5} className={classes.mlAuto}>
              <InfoArea
                className={classes.info1}
                title="Find us at the office"
                description={
                  <p>
                    Koothattukulam P. O. Ernakulam District,
                    <br /> Kerala, India
                    <br />
                    PIN - 686 662
                  </p>
                }
                icon={PinDrop}
                iconColor="primary"
              />
              <InfoArea
                className={classes.info}
                title="Give us a ring"
                description={
                  <p>
                    0485 2250079 (Office)
                    <br />
                    +91 8086488844 (Office)
                    <br />
                    +91 9495194199 (Principal)
                  </p>
                }
                icon={Phone}
                iconColor="primary"
              />
              <InfoArea
                className={classes.info}
                title="Legal Information"
                description={
                  <p>
                    marygiricollege@gmail.com (Office) <br />
                    cmimarygiricollege@gmail.com ( Administrator) <br />
                    james.george.ned@gmail.com ( principal) <br />
                    hrmarygiricollege@gmail.com ( HR )
                  </p>
                }
                icon={BusinessCenter}
                iconColor="primary"
              />
            </GridItem>
          </GridContainer>
        </div>
      </div>
    </div>
  );
}
