import React from "react";
// @material-ui/core components
import { Domain } from "Domain";
import { makeStyles } from "@material-ui/core/styles";
// @material-ui/icons
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import { LazyLoadImage } from "react-lazy-load-image-component";

import blogsStyle from "assets/jss/material-kit-pro-react/views/landingPageSections/EventItemStyle.js";

const useStyles = makeStyles(blogsStyle);

export default function Event({ events }) {
  const classes = useStyles();
  return (
    <GridContainer style={{ textAlign: "left" }}>
      {events.map((event) => (
        <GridItem
          xs={12}
          sm={10}
          md={6}
          className={classes.mlAuto + " " + classes.mrAuto}
        >
          <Card className={classes.card}>
            <GridContainer>
              <GridItem xs={12} sm={4} md={5}>
                <CardHeader image>
                  <a href="##pablito" onClick={(e) => e.preventDefault()}>
                    <LazyLoadImage src={Domain + event.image} alt="..." />
                  </a>
                  <div
                    className={classes.coloredShadow}
                    style={{
                      backgroundImage: `url(${Domain + event.image})`,
                      opacity: "1",
                    }}
                  />
                  <div
                    className={classes.coloredShadow}
                    style={{
                      backgroundImage: `url(${Domain + event.image})`,
                      opacity: "1",
                    }}
                  />
                </CardHeader>
              </GridItem>
              <GridItem xs={12} sm={8} md={7}>
                <h4 className={classes.cardTitle}>
                  <a href="##pablo" onClick={(e) => e.preventDefault()}>
                    {event.name}
                  </a>
                </h4>
                <p className={classes.description}>{event.description}</p>
                <p className={classes.author}>
                  On{" "}
                  <a href="##pablo" onClick={(e) => e.preventDefault()}>
                    <b>{event.date}</b>
                  </a>{" "}
                </p>
              </GridItem>
            </GridContainer>
          </Card>
        </GridItem>
      ))}
    </GridContainer>
  );
}
