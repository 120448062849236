/*eslint-disable*/
import React, { useEffect, useState } from "react";
// nodejs library that concatenates classes
import { Domain } from "Domain";
import classNames from "classnames";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
// @material-ui/icons
import Favorite from "@material-ui/icons/Favorite";
// core components
import Header from "components/Header/Header.js";
import HeaderLinks2 from "components/Header/HeaderLinks2.js";
import Parallax from "components/Parallax/Parallax.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Footer from "components/Footer/Footer.js";
import Button from "components/CustomButtons/Button.js";
import ArrowRightAltIcon from "@material-ui/icons/ArrowRightAlt";
import ArrowDownAltIcon from "@material-ui/icons/ArrowDownward";

import CoursesStyle from "assets/jss/material-kit-pro-react/views/CoursesStyle.js";
import Card from "components/Card/Card";
import CardHeader from "components/Card/CardHeader";

import img5 from "assets/img/graduateCap.svg";
import logo_white from "assets/img/logo_white.png";

import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import { Dialog } from "@material-ui/core";
import Slide from "@material-ui/core/Slide";
import NavPills from "components/NavPills/NavPills.js";
import axios from "axios";

// import { courses } from "./courses.json";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

const useStyles = makeStyles(CoursesStyle);

export default function CoursesPage() {
  const [CoursesModal, setCoursesModal] = React.useState(false);
  const [DialogDetails, setDialogDetails] = React.useState("");
  const [value, setValue] = React.useState();

  const [CourseName, setCourseName] = React.useState("Course name");
  const [detailed_syllabus, setDetailedSyllabus] = React.useState("syllabus");
  const [outline, setOutline] = React.useState("outline");
  const [Eligibility, setEligibility] = React.useState("eligibility");

  const handleModelbox = (currentArrayIndex) => {
    setCoursesModal(true);

    setCourseName(courses[currentArrayIndex].CourseName);
    setDetailedSyllabus(courses[currentArrayIndex].detailed_syllabus);
    setOutline(courses[currentArrayIndex].syllabus_outline);
    setEligibility(courses[currentArrayIndex].Eligibility);
  };
  // setValue({CoursesModal.val});

  // setCourseObjectiveHeadding(AdmissionCourses[event.target.value].CourseObjectiveHeadding);

  React.useEffect(() => {
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
  });
  const classes = useStyles();
  // const Domain = "http://rahulrajrahu33.pythonanywhere.com";
  const [courses, setCourses] = useState([]);

  useEffect(() => {
    let passData = {
      PageIndex: 0,
      PageSize: 10,
    };
    axios({
      method: "post",
      url: Domain + "/api/Admin/GetAllCourses.php",
      data: JSON.stringify(passData),
      config: { headers: { "Content-Type": "multipart/form-data" } },
    })
      .then(function (response) {
        console.log("courses::",response.data.Data)
        setCourses(response.data.Data);
        // if (json.Data.length == 0) setEmpty(true);
      
      })
      .catch(function (response) {
        //handle error
        console.log(response);
      });
    
    // fetch(Domain + "/api/Admin/GetAllCourses/", {
    //   method: "POST",
    //   headers: {
    //     Accept: "application/json",
    //     "Content-Type": "application/json",
    //   },
    //   body: JSON.stringify(passData),
    // })
    //   .then((response) => response.json())

    //   .then((json) => {
    //     setCourses(json.Data);
    //     console.log(json.Data);
    //     // if (json.Data.length == 0) setEmpty(true);
    //     // setLoading(false);
    //   });
  }, []);

  return (
    <div>
      <Parallax image={require("assets/img/coursesBg.webp")} small>
        <div className={classes.container}>
          <GridContainer>
            <GridItem
              xs={12}
              md={6}
              sm={6}
              lg={6}
              className={classNames(classes.mlAuto, classes.mrAuto)}
            >
              <p className={classes.description1}>Home/Courses</p>
              <h1 className={classes.title}>Courses</h1>
            </GridItem>
            <GridItem
              xs={12}
              md={6}
              sm={6}
              lg={6}
              className={classNames(
                classes.mlAuto,
                classes.mrAuto,
                classes.textRight
              )}
            >
              <img src={img5} alt=".." />
            </GridItem>
          </GridContainer>
        </div>
      </Parallax>
      <div className={classes.main}>
        <div className={classes.container}>
          <GridContainer className={classNames(classes.mlAuto, classes.mrAuto)}>
            <GridItem xs={12} sm={12} md={12}>
              <p className={classes.description}>
                Marygiri College of Arts and Science, Koothattukulam is an
                institution managed by CMI Fathers of St.Joseph Province,
                Kottayam. This institution is an embodiment of the dreams and
                visions of Saint Kuriakose Elias Chavara,the greatest
                educational visionary of the 19th century and the founder of the
                CMI congregation The college aims at forming leaders who are
                intellectually competent, spiritually mature, morally upright,
                psychologically integrated, physically healthy and socially
                acceptable who will champion the cause of justice, love, truth
                and peace and who are ever open to further growth
              </p>
            </GridItem>
          </GridContainer>

          <hr />
          <GridContainer
            className={classNames(
              classes.mlAuto,
              classes.mrAuto,
              classes.textCenter
            )}
          >
            <GridItem xs={12} sm={12} md={12}>
              <h2 className={classes.title2}>UG Courses</h2>
            </GridItem>
          </GridContainer>

          <GridContainer className={classNames(classes.mlAuto, classes.mrAuto)}>
            {courses.map((course, i) => (
              <GridItem xs={12} sm={6} md={6} key={i}>
                <Card blog className={classes.card}>
                  <GridContainer>
                    <GridItem xs={12} sm={12} md={5}>
                      <CardHeader image plain>
                        <a href="##pablito" onClick={(e) => e.preventDefault()}>
                          <img src={Domain + course.image} alt="..." />
                        </a>
                        <div
                          className={classes.coloredShadow}
                          style={{
                            backgroundImage: `url(${course.img})`,
                            opacity: "1",
                          }}
                        />
                        <div
                          className={classes.coloredShadow}
                          style={{
                            backgroundImage: `url(${course.img})`,
                            opacity: "1",
                          }}
                        />
                      </CardHeader>
                    </GridItem>
                    <GridItem xs={12} sm={12} md={7}>
                      <GridContainer>
                        <GridItem xs={12} sm={12} md={12}>
                          <div style={{ minHeight: "200px" }}>
                            <h4 className={classes.cardTitle}>
                              {course.coursename}
                            </h4>
                            <p className={classes.description}>
                              {course.description}
                            </p>
                          </div>
                        </GridItem>
                        <GridItem xs={12} sm={12} md={12}>
                          <Button
                            color="primary"
                            size="lg"
                            onClick={() => handleModelbox(i)}
                          >
                            Learn More
                            <ArrowRightAltIcon
                              style={{
                                marginLeft: "20px",
                                marginRight: "-20px",
                              }}
                            />
                          </Button>
                        </GridItem>
                      </GridContainer>

                      <Dialog
                        classes={{
                          root: classes.modalRoot,
                          paper: classes.modal + " " + classes.modalLogin,
                        }}
                        open={CoursesModal}
                        TransitionComponent={Transition}
                        keepMounted
                        onClose={() => setCoursesModal(false)}
                        aria-labelledby="login-modal-slide-title"
                        aria-describedby="login-modal-slide-description"
                      >
                        <Card plain className={classes.modalLoginCard}>
                          <DialogTitle
                            id="login-modal-slide-title"
                            disableTypography
                            className={classes.modalHeader}
                          ></DialogTitle>
                          <DialogContent
                            id="login-modal-slide-description"
                            className={classes.modalBody}
                          >
                            <h3 className={classes.title2}>{CourseName}</h3>
                            <NavPills
                              alignCenter
                              color="primary"
                              horizontal={{
                                tabsGrid: { xs: 12, sm: 4, md: 4 },
                                contentGrid: { xs: 12, sm: 8, md: 8 },
                              }}
                              tabs={[
                                {
                                  tabButton: "Syllabus Outlines",

                                  tabContent: (
                                    <span>
                                      <p className={classes.title1}>
                                        Click the below Button to download the
                                        Syllbus Outline
                                      </p>
                                      <Button
                                        color="primary"
                                        size="lg"
                                        href={Domain + course.syllabus}
                                      >
                                        Download
                                        <ArrowDownAltIcon
                                          style={{
                                            marginLeft: "20px",
                                            marginRight: "-20px",
                                          }}
                                        />
                                      </Button>
                                    </span>
                                  ),
                                },
                                {
                                  tabButton: "Detailed Syllabus",

                                  tabContent: (
                                    <span>
                                      <p className={classes.title1}>
                                        Click the below Button to download the
                                        Detailed Syllabus
                                      </p>
                                      <Button
                                        color="primary"
                                        size="lg"
                                         href={Domain + course.syllabus}
                                      >
                                        Download
                                        <ArrowDownAltIcon
                                          style={{
                                            marginLeft: "20px",
                                            marginRight: "-20px",
                                          }}
                                        />
                                      </Button>
                                    </span>
                                  ),
                                },
                                {
                                  tabButton: "Eligibility Criteria",

                                  tabContent: (
                                    <span>
                                      <p className={classes.title1}>
                                        {" "}
                                        {Eligibility}
                                      </p>
                                    </span>
                                  ),
                                },
                              ]}
                            />
                          </DialogContent>
                        </Card>
                      </Dialog>
                    </GridItem>
                  </GridContainer>
                </Card>
              </GridItem>
            ))}
          </GridContainer>
        </div>
        <br />
        <br />
      </div>

      <Footer
        theme="dark"
        content={
          <div>
            <div className={classes.center}>
              Copyright &copy; {1900 + new Date().getYear()}{" "}
              <a href="#" target="_blank" className={classes.aClasses}>
                MaryGiri College,
              </a>{" "}
              All Rights Reserved.
            </div>
          </div>
        }
      >
        <div className={classes.containerLimit}>
          <GridContainer>
            <GridItem xs={12} sm={4} md={4}>
              <img
                className={classes.footerLogo}
                src={logo_white}
                alt="office1"
              />

              <h5>Contact Address</h5>
              <p>
                Koothattukulam P. O. Ernakulam District, Kerala, India PIN - 686
                662
              </p>
              <p>
                0485 2250079, 8086488844 <br />
                marygiricollege@gmail.com
              </p>
            </GridItem>
            <GridItem xs={1} sm={1} md={1}>
              <div
                style={{
                  backgroundColor: "#5c5c5c",
                  width: "1px",
                  height: "100%",
                }}
              />
            </GridItem>
            <GridItem xs={11} sm={3} md={3}>
              <h5>Quick Links</h5>
              <div className={classes.socialFeed}>
                <div>
                  <a href="#/contact-us">
                    <p>Contact Us</p>
                  </a>
                </div>
                <div>
                  <a href="#/online-admission">
                    <p>Online Admission</p>
                  </a>
                </div>
                <div>
                  <p>Online Fee Payment</p>
                </div>
                <div>
                  <a href="#/courses-page">
                    {" "}
                    <p>Courses</p>
                  </a>
                </div>
              </div>
            </GridItem>
            <GridItem xs={1} sm={1} md={1}>
              <div
                style={{
                  backgroundColor: "#5c5c5c",
                  width: "1px",
                  height: "100%",
                }}
              />
            </GridItem>
            <GridItem xs={11} sm={2} md={2}>
              <h5>Follow Us on</h5>
              <div className={classes.socialFeed}>
                <Button
                  color="facebook"
                  round
                  justIcon
                  href="#https://www.facebook.com/marygiricollege1"
                >
                  <i className="fab fa-facebook-f" />
                </Button>
                &nbsp;
                <Button
                  color="instagram"
                  round
                  justIcon
                  href="#https://instagram.com/marygiri_college_official?utm_medium=copy_link"
                >
                  <i className="fab fa-instagram" />
                </Button>
                &nbsp;
                <Button
                  color="twitter"
                  round
                  justIcon
                  href="#https://www.youtube.com/channel/UCEVrpzL3xtWXEwoameOUqUQ"
                >
                  <i className="fab fa-youtube" />
                </Button>
              </div>
            </GridItem>
          </GridContainer>
        </div>
      </Footer>
    </div>
  );
}
