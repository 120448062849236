/* eslint-disable */
import React, { useEffect, useState } from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import { Domain } from "Domain";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Card from "components/Card/Card.js";

import projectsStyle from "assets/jss/material-kit-pro-react/views/landingPageSections/placementStyle.js";
import classNames from "classnames";
import CardHeader from "components/Card/CardHeader";
import { LazyLoadImage } from "react-lazy-load-image-component";

const useStyles = makeStyles(projectsStyle);

export default function Publications({}) {
  const classes = useStyles();
  // const Domain = "http://rahulrajrahu33.pythonanywhere.com";
  const [publications, setPublications] = useState([]);
  //const clasround = useStylesround();
  //data from json file
  useEffect(() => {
    let passData = {
      PageIndex: 0,
      PageSize: 0,
    };
    fetch(Domain + "/api/Admin/GetAllPublications/", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(passData),
    })
      .then((response) => response.json())

      .then((json) => {
        setPublications(json.Data);
        // if (json.Data.length == 0) setEmpty(true);
        // setLoading(false);
      });
  }, []);
  return (
    <div className="cd-section">
      <div
        className={
          classes.projects + " " + classes.sectionDark + " " + classes.projects3
        }
      >
        <div className={(classes.containerm, classes.containerLimit)}>
          <GridContainer className={classNames(classes.mlAuto, classes.mrAuto)}>
            {publications.map((item, i) => (
              <GridItem xs={12} sm={6} md={4}>
                <Card blog className={classes.card1}>
                  <GridContainer>
                    <GridItem xs={12} sm={12} md={4}>
                      <CardHeader image plain>
                        <a href="##pablito" onClick={(e) => e.preventDefault()}>
                          <LazyLoadImage src={Domain + item.Image} alt="..." />
                        </a>
                        <div
                          className={classes.coloredShadow}
                          style={{
                            backgroundImage: `url(${item.img})`,
                            opacity: "1",
                          }}
                        />
                        <div
                          className={classes.coloredShadow}
                          style={{
                            backgroundImage: `url(${item.img})`,
                            opacity: "1",
                          }}
                        />
                      </CardHeader>
                    </GridItem>
                    <GridItem xs={12} sm={12} md={7}>
                      <GridContainer>
                        <GridItem xs={12} sm={12} md={12}>
                          <div>
                            <h4 className={classes.cardTitle1}>{item.Name}</h4>
                            <br />
                            <br />
                            <span className={classes.description}>
                              Published By :{" "}
                            </span>
                            {item.PublishedBy}
                            <br />
                            <br />
                            <span className={classes.description}>
                              Published date:{" "}
                            </span>
                            {item.PublishedDate}
                          </div>
                        </GridItem>
                        <GridItem xs={12} sm={12} md={12}></GridItem>
                      </GridContainer>
                    </GridItem>
                  </GridContainer>
                </Card>
              </GridItem>
            ))}
          </GridContainer>
        </div>
      </div>
    </div>
  );
}
