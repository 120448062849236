import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import { about } from "assets/aboutUsData.js";

import descriptionStyle from "assets/jss/material-kit-pro-react/views/aboutUsSections/descriptionStyle.js";

const useStyles = makeStyles(descriptionStyle);

export default function SectionDescription() {
  const classes = useStyles();


  const description1 = [],description2 = [],description3 = [],description4 = [];
for (var i in about) {
  description1.push( about[i].description1 );
  description2.push( about[i].description2 );
  description3.push( about[i].description3 );
  description4.push( about[i].description4 );
    }

  
  return (
    <div >
    <div className={classes.aboutDescription}>
      <GridContainer>
        <GridItem
          md={12}
          sm={12}
          className={classNames(classes.mrAuto, classes.mlAuto)}
        >
          <h5>  {description1}  </h5>
          <h5>  {description2}  </h5> 
          <h5>  {description3}  </h5> 
          <h5>  {description4}  </h5> 
        </GridItem>
      </GridContainer>
    </div>
    </div>
  );
}
