import React, { useEffect, useState } from "react";
// @material-ui/core components
import { Domain } from "Domain";
import { makeStyles } from "@material-ui/core/styles";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import { Button } from "@material-ui/core";

import productStyle from "assets/jss/material-kit-pro-react/views/landingPageSections/productStyle.js";
import Event from "./Events";
import ArrowDownwardIcon from "@material-ui/icons/ArrowDownward";
import axios from "axios";
const useStyles = makeStyles(productStyle);

export default function EventSection({ size, index, landingpage }) {
  const classes = useStyles();
  const [events, setEvents] = useState([]);
  useEffect(() => {
    let passData = {
      PageIndex: index,
      PageSize: size,
    };
    //API call for get latest 10 elements


    axios({
      method: "post",
      url: Domain + "/api/Admin/GetAllEvents.php",
      data: JSON.stringify(passData),
      config: { headers: { "Content-Type": "multipart/form-data" } },
    })
      .then(function (response) {
        console.log("succcess",response.data)
        setEvents(response.data.Data);
        // if (json.Data.length == 0) setEmpty(true);
      
      })
      .catch(function (response) {
        //handle error
        console.log(response);
      });


    // fetch(Domain + "/api/Admin/GetAllEvents/", {
    //   method: "POST",
    //   headers: {
    //     Accept: "application/json",
    //     "Content-Type": "application/json",
    //   },
    //   body: JSON.stringify(passData),
    // })
    //   .then((response) => response.json())

    //   .then((json) => {
    //     setEvents(json.Data);
    //   });
  }, [size, index]);
  return (
    <div className={classes.main}>
      <div className={classes.section}>
        <GridContainer justify="center">
          <GridItem xs={12} sm={8} md={8}>
            <h2 className={classes.title}>Latest News + Events</h2>
          </GridItem>

          <GridItem xs={12} sm={6} md={12}>
            {events && <Event events={events} />}
          </GridItem>

          <GridItem xs={12} sm={12} md={8}>
            {landingpage ? (
              <Button
                href="#/events-page"
                variant="outlined"
                size="large"
                color="primary"
              >
                Load More
                <ArrowDownwardIcon fontSize="inherit" />
              </Button>
            ) : (
              <div></div>
            )}
          </GridItem>
        </GridContainer>
      </div>
    </div>
  );
}
