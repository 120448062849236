import React from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// @material-ui/icons
// import  from "@material-ui/icons/";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter.js";
import Button from "components/CustomButtons/Button.js";
import ArrowRightAltIcon from "@material-ui/icons/ArrowRightAlt";
import { LazyLoadImage } from "react-lazy-load-image-component";

import teamsStyle from "assets/jss/material-kit-pro-react/views/sectionsSections/teamsStyle.js";

import ScrollAnimation from "react-animate-on-scroll";
import presentationiPad from "assets/img/sectionBg/joinImage.jpg";

const useStyles = makeStyles(teamsStyle);

export default function SectionCourses({ ...rest }) {
  const classes = useStyles();
  return (
    <div>
      {/* Team 3 START */}
      <div className={classes.team}>
        <div className={classes.container}>
          <GridContainer>
            <GridItem xs={12} sm={12} md={12}>
              <Card profile plain className={classes.card3}>
                <GridContainer justify="center">
                  <GridItem xs={12} sm={7} md={5}>
                    <ScrollAnimation animateIn="animate__fadeInLeft">
                      <CardBody plain>
                        <h2 className={classes.cardTitle}>
                          Join the Marygiri Family
                        </h2>

                        <p className={classes.description}>
                          Marygiri ensures everything possible to equip the
                          students with the best possible teaching resources,
                          practical exposure and training methods so that
                          students are able to shape up their life and career
                          for a challenging world out there. In our college, we
                          believe in inspiring minds, improving their
                          intellectual capacity and building characters which
                          our students will treasure for their lifetime.
                          Additionally, we endeavour to inculcate social values
                          and principles of personal excellence and care for
                          others and also teaches how to handle challenging
                          circumstances.
                        </p>
                      </CardBody>
                      <CardFooter profile plain>
                        <Button color="primary" size="lg" href="#/courses-page">
                          Apply For Admission
                          <ArrowRightAltIcon
                            style={{ marginLeft: "20px", marginRight: "-20px" }}
                          />
                        </Button>
                      </CardFooter>
                    </ScrollAnimation>
                  </GridItem>
                  <GridItem xs={12} sm={5} md={7}>
                    <CardHeader image plain>
                      <a href="##pablo" onClick={(e) => e.preventDefault()}>
                        <LazyLoadImage src={presentationiPad} alt="..." />
                      </a>
                      <div
                        className={classes.coloredShadow}
                        style={{
                          backgroundImage: `url(${presentationiPad})`,
                          opacity: "1",
                        }}
                      />
                    </CardHeader>
                  </GridItem>
                </GridContainer>
              </Card>
            </GridItem>
          </GridContainer>
        </div>
      </div>
      {/* Team 3 END */}
    </div>
  );
}
