import React from "react";
// nodejs library that concatenates classes

import classNames from "classnames";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

import officeStyle from "assets/jss/material-kit-pro-react/views/aboutUsSections/officeStyle.js";

// office
import james from "assets/img/StaffsImages/James.jpg";
import shibin from "assets/img/StaffsImages/Shibin.jpg";


const useStyles = makeStyles(officeStyle);

export default function Office() {
  const classes = useStyles();
  return (
    <div className={classes.office}>
      <GridContainer>
        <GridItem
          md={12}
          sm={12}
          className={classNames(classes.mrAuto, classes.mlAuto)}
        >
          The routine office transactions such as the Admission, issue and
          maintenance of certificates, records, daily accounts are done through
          the office. The office is open for the public from 9.00am to 4.30pm
          from Monday to Saturday, except on holidays.
        </GridItem>
      </GridContainer>
      <GridContainer>
        <GridItem
          xs={12}
          md={3}
          sm={3}
          className={classNames(classes.mrAuto, classes.mlAuto)}
        >
          <center>
            <img src={james} alt="" width="250px" />
            <br />
            Office Superintendent – James Joseph
          </center>
        </GridItem>
        <GridItem
          xs={12}
          md={3}
          sm={3}
          className={classNames(classes.mrAuto, classes.mlAuto)}
        >
          <center>
            <img src={shibin} alt="" width="250px" />
            <br />
            Office Assistant – Shibin Thomas
          </center>
        </GridItem>
      </GridContainer>
    </div>
  );
}
