import React from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// @material-ui/icons

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import clt1 from "assets/img/clients/clt1.png";
import clt2 from "assets/img/clients/clt2.png";
import clt3 from "assets/img/clients/clt3.png";
import clt4 from "assets/img/clients/clt4.png";
import clt5 from "assets/img/clients/clt5.png";
import { LazyLoadImage } from "react-lazy-load-image-component";

import workStyle from "assets/jss/material-kit-pro-react/views/landingPageSections/workStyle.js";

const useStyles = makeStyles(workStyle);

export default function OurClients() {
  const classes = useStyles();
  return (
    <div className={classes.section}>
      <div className={classes.ourClients}>
        <GridContainer justify="center">
          <GridItem xs={12} md={8} sm={8}>
            <h2 className={classes.title}>Where our Alumni Working</h2>
            <br />
            <br />
          </GridItem>
          <GridItem xs={12} md={12} sm={12}>
            <GridContainer justify="center">
              <GridItem xs={6} md={2} sm={2}>
                <LazyLoadImage src={clt1} alt="clt1" />
              </GridItem>
              <GridItem xs={6} md={2} sm={2}>
                <LazyLoadImage src={clt2} alt="clt2" />
              </GridItem>
              <GridItem xs={6} md={2} sm={2}>
                <LazyLoadImage src={clt3} alt="clt3" />
              </GridItem>
              <GridItem xs={6} md={2} sm={2}>
                <LazyLoadImage src={clt4} alt="clt4" />
              </GridItem>
              <GridItem xs={6} md={2} sm={2}>
                <LazyLoadImage src={clt5} alt="clt5" />
              </GridItem>
            </GridContainer>
          </GridItem>
        </GridContainer>
      </div>
    </div>
  );
}
