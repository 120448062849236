import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

import officeStyle from "assets/jss/material-kit-pro-react/views/aboutUsSections/officeStyle.js";

// office
import office1 from "assets/img/gallery/04.jpg";
import student2 from "assets/img/gallery/27.jpg";

const useStyles = makeStyles(officeStyle);

export default function SectionMission() {
  const classes = useStyles();
  return (
    <div className={classes.office}>
      <GridContainer>
        <GridItem
          md={12}
          sm={12}
          className={classNames(classes.mrAuto, classes.mlAuto)}
        >
          <h2 className={classes.title}>Mission & Vision</h2>
        </GridItem>
      </GridContainer>
      <GridContainer>
        <GridItem md={12} sm={12} lg={12}>
          <center>
            <img
              className={classNames(
                classes.imgRaised,
                classes.imgFluid,
                classes.rounded
              )}
              src={office1}
              alt="office1"
            />
          </center>
        </GridItem>
      </GridContainer>
      <h3 className={classes.headding3}> Mission </h3>
      <h5>
        It is our objective to provide an arena where students can cross swords
        and emerge as victors intellectually, physically, mentally and
        spiritually and who are ever proud of the rich culture and heritage of
        the motherland.
      </h5>
      <GridContainer justify="center">
        <GridItem
          md={5}
          sm={5}
          lg={5}
          className={classNames(classes.mlAuto, classes.mrAuto)}
        >
          <br />
          <ol>
            <h6 className={classes.headding3}>Salient Features</h6>
            <br />
            <li>Industry Interface</li>
            <br />
            <li>Hostel Facilities</li>
            <br />
            <li>Experienced Faculty</li>
            <br />
            <li>Oppertunities for Campus Placement</li>
            <br />
            <li>Add-on Courses</li>
            <br />
            <li>Community Partnership Programmes</li>
            <br />
          </ol>
        </GridItem>
        <GridItem md={7} sm={7} lg={7}>
          <img
            className={classNames(
              classes.imgRaised,
              classes.imgFluid,
              classes.rounded
            )}
            src={student2}
            alt="office1"
          />
        </GridItem>
      </GridContainer>
      <h3 className={classes.headding3}>Vission</h3>
      <h5>
        Myrigiri college of Arts and Science aims at imparting exemplary
        education which enhances the multifaceted abilities of the students and
        enables them to explore and seize upon the opportunities in an
        accessible terrain.
      </h5>
    </div>
  );
}
