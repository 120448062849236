import React from "react";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Card from "components/Card/Card.js";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// library used for cool animations
import ScrollAnimation from "react-animate-on-scroll";
import CardHeader from "components/Card/CardHeader";
import { LazyLoadImage } from "react-lazy-load-image-component";

import "animate.css/animate.min.css";

import officeStyle from "assets/jss/material-kit-pro-react/views/aboutUsSections/officeStyle.js";

import { Domain } from "Domain";

const useStyles = makeStyles(officeStyle);

export default function SectionMessages({ management }) {
  console.log(management);
  const classes = useStyles();
  return (
    <div>
      <div className={classes.container}>
        <h2 className={classes.title}>Management</h2>
        {management.map((item, i) => {
          return (
            <ScrollAnimation animateIn="animate__fadeInLeft">
              <GridItem xs={12} sm={12} md={12} key={i}>
                <Card blog className={classes.card1}>
                  <GridContainer>
                    {i % 2 === 0 ? (
                      <>
                        <GridItem
                          xs={12}
                          sm={12}
                          md={4}
                          style={{ margin: "40px" }}
                        >
                          <CardHeader image plain>
                            <LazyLoadImage
                              src={Domain + item.Image}
                              alt="..."
                            />

                            <div
                              className={classes.coloredShadow}
                              style={{
                                backgroundImage: `url(${Domain + item.Image})`,
                                opacity: "1",
                              }}
                            />
                            <div
                              className={classes.coloredShadow}
                              style={{
                                backgroundImage: `url(${Domain + item.Image})`,
                                opacity: "1",
                              }}
                            />
                          </CardHeader>
                        </GridItem>
                        <GridItem
                          xs={12}
                          sm={12}
                          md={6}
                          style={{ margin: "40px" }}
                        >
                          <h3 className={classes.title}>{item.Post} </h3>
                          <h4>{item.Name}</h4>
                          <h5 className={classes.description}>
                            {item.Description}
                          </h5>
                        </GridItem>
                      </>
                    ) : (
                      <>
                        <GridItem
                          xs={12}
                          sm={12}
                          md={6}
                          style={{ margin: "40px" }}
                        >
                          <h3 className={classes.title}>{item.Post} </h3>
                          <h4>{item.Name}</h4>
                          <h5 className={classes.description}>
                            {item.Description}
                          </h5>
                        </GridItem>
                        <GridItem
                          xs={12}
                          sm={12}
                          md={4}
                          style={{ margin: "40px" }}
                        >
                          <CardHeader image plain>
                            <LazyLoadImage
                              src={Domain + item.Image}
                              alt="..."
                            />

                            <div
                              className={classes.coloredShadow}
                              style={{
                                backgroundImage: `url(${Domain + item.Image})`,
                                opacity: "1",
                              }}
                            />
                            <div
                              className={classes.coloredShadow}
                              style={{
                                backgroundImage: `url(${Domain + item.Image})`,
                                opacity: "1",
                              }}
                            />
                          </CardHeader>
                        </GridItem>
                      </>
                    )}
                  </GridContainer>
                </Card>
              </GridItem>
            </ScrollAnimation>
          );
        })}

        {/* <div className={classes.imageContainer}>
          <GridContainer>
            <GridItem xs={12} sm={12} md={6} className={classes.animeAreaImg}>
              <div>
                <img
                  style={{ height: "400px" }}
                  className={classes.ipadImg}
                  src={augustin}
                  alt="FR. AUGUSTINE MLAVARAYIL"
                />
              </div>
            </GridItem>
            <GridItem xs={12} sm={12} md={6} className={classes.animeInfoImg}>
              <div>
                <ScrollAnimation animateIn="animate__fadeInRight">
                  <Card className={classes.card + " " + classes.messageCard}>
                    <div>
                      <h3 className={classes.title}>Administrator </h3>
                      <h4>Rev.Fr. Augustine Mlavarayil CMI</h4>
                      <h5 className={classes.description}>
                        The BCA degree prepares the students for manpower
                        requirement of the fast developing IT/Software industry.
                        Students that join the department of BCA at the college
                        come from a varied backgrounds. To enable them to
                        familiarise with the course, orientation classes are
                        held to make students feel at ease with the content of
                        the degree curriculum as well as to develop rapport with
                        their peers and classmates.
                      </h5>
                    </div>
                  </Card>
                </ScrollAnimation>
              </div>
            </GridItem>
          </GridContainer>
        </div> */}

        {/* <div className={classes.imageContainer}>
          <GridContainer>
            <GridItem xs={12} sm={12} md={6} className={classes.animeInfoImg}>
              <div>
                <ScrollAnimation animateIn="animate__fadeInLeft">
                  <Card className={classes.card + " " + classes.messageCard}>
                    <div>
                      <h3 className={classes.title}>Principal </h3>
                      <h4> Prof. Dr. James George</h4>
                      <h5 className={classes.description}>
                        The BCA degree prepares the students for manpower
                        requirement of the fast developing IT/Software industry.
                        Students that join the department of BCA at the college
                        come from a varied backgrounds. To enable them to
                        familiarise with the course, orientation classes are
                        held to make students feel at ease with the content of
                        the degree curriculum as well as to develop rapport with
                        their peers and classmates.
                      </h5>
                    </div>
                  </Card>
                </ScrollAnimation>
              </div>
            </GridItem>
            <GridItem xs={12} sm={12} md={6} className={classes.animeAreaImg}>
              <div>
                <img
                  className={classes.ipadImg}
                  src={james}
                  alt=" RProf. Dr. James George"
                />
              </div>
            </GridItem>
          </GridContainer>
        </div> */}
      </div>
    </div>
  );
}
