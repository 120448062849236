import React from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

import officeStyle from "assets/jss/material-kit-pro-react/views/aboutUsSections/officeStyle.js";

// office

const useStyles = makeStyles(officeStyle);

export default function ComputerDep({ depDetails }) {
  const classes = useStyles();
  return (
    <div className={classes.office}>
      <h3 className={classes.headding3}>{depDetails.departmentname}</h3>
      <h4 className={classes.headding3}>
        {" "}
        Head of the Department: {depDetails.headname}
      </h4>
      <h5>{depDetails.description}</h5>
    </div>
  );
}
