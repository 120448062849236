import React from "react";
// nodejs library that concatenates classes

import classNames from "classnames";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

import officeStyle from "assets/jss/material-kit-pro-react/views/aboutUsSections/officeStyle.js";

// office
import Table from "assets/img/StaffsImages/table.jpg.jpg";
import Anish from "assets/img/StaffsImages/Anish.jpg";

const useStyles = makeStyles(officeStyle);

export default function Library() {
  const classes = useStyles();
  return (
    <div className={classes.office}>
      <h3 className={classes.headding3}> Timing </h3>
      <h5>
        Monday to Friday – 9.15 am to 4.00 pm
        <br />
        Saturdays – 10.00 am to 2.30 pm
      </h5>
      <br />
      <h3 className={classes.headding3}> Guidelines </h3>
      1. Members/ users have to produce their I D cards and sign the register at
      the entrance before entering the library. <br />
      2. Members/ users have to keep their personal belongings outside the
      Library.
      <br />
      3. Users of library are not permitted to take personal copies of books or
      other printed materials inside the library.
      <br />
      4. Silence shall be strictly observed inside the library.
      <br />
      5. Books, journals, other documents, library equipment’s, etc., should be
      handled with care.
      <br />
      6. Marking, underlining, writing or defacing the library books, will lead
      to fining and other punishments.
      <br />
      7.General reference books and journals will not be issued.
      <br />
      8. Before leaving the counter the borrowers shall examine the books taken
      by them and point out to the Librarian, any damage or mutilation or defect
      in the books they are taking. The borrowers will be held responsible for
      any damage or mutilation observed at the time of return.
      <br />
      9. Librarian reserves the right to recall at any time any book issued from
      the library.
      <br />
      10. Library and its premises should be kept absolutely clean and tidy.
      <br />
      11.Carrying & use of mobile phones within the college library is banned.
      <br />
      12. All students must return the books borrowed from library and obtain no
      dues certificate from librarian before applying for T C.
      <br />
      <h3 className={classes.headding3}> Lending of books </h3>
      <h5>
        The members are entitled to borrow books from Library as per following
        schedule
      </h5>
      <br />
      <img src={Table} alt="" />
      <br />
      <GridContainer>
        <GridItem
          xs={12}
          md={3}
          sm={3}
          className={classNames(classes.mrAuto, classes.mlAuto)}
        >
          <center>
            <img src={Anish} alt="" width="250px" height="260px" />
            <br />
            Office Assistant – Shibin Thomas
          </center>
        </GridItem>
        <GridItem
          xs={12}
          md={3}
          sm={3}
          className={classNames(classes.mrAuto, classes.mlAuto)}
        ></GridItem>
        <GridItem
          xs={12}
          md={3}
          sm={3}
          className={classNames(classes.mrAuto, classes.mlAuto)}
        ></GridItem>
        <GridItem
          xs={12}
          md={3}
          sm={3}
          className={classNames(classes.mrAuto, classes.mlAuto)}
        ></GridItem>
      </GridContainer>
      <h3>Librarian - Anish Thomas</h3>
      <br />
      <h3 className={classes.headding3}> Contact </h3>
      Anish Thomas,
      <br />
      Librarian
      <br />
      Marygiri College of Arts & Science, Koothattukulam
      <br />
      Ernakulam PIN – 686662. Kerala.
      <br />
      Phone: 8086488844 Email:{" "}
      <a href="#marygiri.com">marygiricollege@gmail.com</a>
      <br />
    </div>
  );
}
