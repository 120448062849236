import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

import officeStyle from "assets/jss/material-kit-pro-react/views/aboutUsSections/officeStyle.js";

// office

const useStyles = makeStyles(officeStyle);

export default function WomenCell() {
  const classes = useStyles();
  return (
    <div className={classes.office}>
    
      <GridContainer>
        <GridItem
          md={12}
          sm={12}
          className={classNames(classes.mrAuto, classes.mlAuto)}
        >
          <h2 className={classes.title}>Women Cell – OUR MISSION AND VISION</h2>
        </GridItem>
        <GridItem
          md={12}
          sm={12}
          className={classNames(classes.mrAuto, classes.mlAuto)}
        >
          National Service Scheme is a Central Government Educational Programme
          started in the year 1969.It is functioning under the ministry of Youth
          Affairs and Sports at the national level and under the Ministry of
          Higher Education at the State level. With the avowed intention of
          benefitting the socio-cultural environment of its locale, the NSS
          programme actively fosters a spirit of social responsibility in the
          students through activities as varied as social service activities,
          surveys, seven day camps, cleaning activities and awareness classes.
          Students enrolled for the NSS programme are required to partake in a
          minimum of 240 hours of work over a period of two consecutive academic
          years and attend a seven-day special camp within this period. On
          completion of these requirements, the students become eligible for
          certificate from the University, which will also guarantee them
          weightage marks as per the University guidelines.
        </GridItem>
      </GridContainer>

      <h5>
        Women Cell of our college aims at the holistic development and
        upliftment of girl students. In our campus both women and men enjoy the
        same right and opportunities across all sectors. We nurture the culture
        where the aspirations of men and women are equally valued. Our vision is
        to promote general wellbeing of female students, teaching and
        non-teaching women staff of the college and to provide and maintain a
        dignified working environment Our cell always pledged to remain
        committed to the elimination of violence against women.
      </h5>
    </div>
  );
}
