/*eslint-disable*/
import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// core components
import Header from "components/Header/Header.js";
import HeaderLinks2 from "components/Header/HeaderLinks2.js";
import Parallax from "components/Parallax/Parallax.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Footer from "components/Footer/Footer.js";
import Button from "components/CustomButtons/Button.js";
// sections for this page

import CoursesStyle from "assets/jss/material-kit-pro-react/views/CoursesStyle.js";
import ContactUs from "./ContactUs";

const useStyles = makeStyles(CoursesStyle);

export default function ContactUsPage() {
  const [specialitySelect, setSpecialitySelect] = React.useState("0");
  const handleSpeciality = (event) => {
    setSpecialitySelect(event.target.value);
  };
  React.useEffect(() => {
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
  });
  const classes = useStyles();
  return (
    <div>
      <Parallax image={require("assets/img/admissionsBg.webp")} small>
        <div className={classes.container}>
          <GridContainer>
            <GridItem
              md={8}
              sm={8}
              lg={12}
              className={classNames(classes.mlAuto, classes.mrAuto)}
            >
              <p className={classes.title}>Home/Contact Us</p>
              <h1 className={classes.title}>Contact Us</h1>
            </GridItem>
          </GridContainer>
        </div>
      </Parallax>
      <div className={classes.main}>
        <div>
          <ContactUs />
        </div>
        <br />
        <br />
      </div>

      <Footer
        theme="dark"
        content={
          <div>
            <div className={classes.center}>
              Copyright &copy; {1900 + new Date().getYear()}{" "}
              <a href="#" target="_blank" className={classes.aClasses}>
                MaryGiri College,
              </a>{" "}
              All Rights Reserved.
            </div>
          </div>
        }
      >
        <GridContainer>
          <GridItem xs={12} sm={4} md={4}>
            <h5>Contact Address</h5>
            <p>
              Koothattukulam P. O. Ernakulam District, Kerala, India PIN - 686
              662
            </p>
            <p>
              0485 2250079, 8086488844 <br />
              marygiricollege@gmail.com
            </p>
          </GridItem>
          <GridItem xs={12} sm={4} md={4}>
            <h5>Quick Links</h5>
            <div className={classes.socialFeed}>
              <div>
                <a href="/contact-us">
                  <p>Contact Us</p>
                </a>
              </div>
              <div>
                <a href="#/online-admission">
                  <p>Online Admission</p>
                </a>
              </div>
              <div>
                <p>Online Fee Payment</p>
              </div>
              <div>
                <a href="/courses-page">
                  {" "}
                  <p>Courses</p>
                </a>
              </div>
            </div>
          </GridItem>
          <GridItem xs={11} sm={2} md={2}>
            <h5>Follow Us on</h5>
            <div className={classes.socialFeed}>
              <Button
                color="facebook"
                round
                justIcon
                href="#https://www.facebook.com/marygiricollege1"
              >
                <i className="fab fa-facebook-f" />
              </Button>
              &nbsp;
              <Button
                color="instagram"
                round
                justIcon
                href="#https://instagram.com/marygiri_college_official?utm_medium=copy_link"
              >
                <i className="fab fa-instagram" />
              </Button>
              &nbsp;
              <Button
                color="twitter"
                round
                justIcon
                href="#https://www.youtube.com/channel/UCEVrpzL3xtWXEwoameOUqUQ"
              >
                <i className="fab fa-youtube" />
              </Button>
            </div>
          </GridItem>
        </GridContainer>
      </Footer>
    </div>
  );
}
