import React from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// @material-ui/icons
// import  from "@material-ui/icons/";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter.js";
import Button from "components/CustomButtons/Button.js";
import ArrowRightAltIcon from "@material-ui/icons/ArrowRightAlt";

import teamsStyle from "assets/jss/material-kit-pro-react/views/sectionsSections/teamsStyle.js";

import img1 from "assets/img/sectionBg/courseSectionImage.jpg";
import ScrollAnimation from "react-animate-on-scroll";
import { LazyLoadImage } from "react-lazy-load-image-component";

const useStyles = makeStyles(teamsStyle);

export default function SectionCourses({ ...rest }) {
  const classes = useStyles();
  return (
    <div>
      {/* Team 3 START */}
      <div className={classes.team}>
        <div className={classes.container}>
          <GridContainer>
            <GridItem xs={12} sm={12} md={12}>
              <Card profile plain className={classes.card3}>
                <GridContainer>
                  <GridItem xs={12} sm={5} md={5}>
                    <CardHeader image plain>
                      <a href="##pablo" onClick={(e) => e.preventDefault()}>
                        <LazyLoadImage src={img1} alt="..." />
                      </a>
                      <div
                        className={classes.coloredShadow}
                        style={{
                          backgroundImage: `url(${img1})`,
                          opacity: "1",
                        }}
                      />
                    </CardHeader>
                  </GridItem>
                  <GridItem xs={12} sm={7} md={7}>
                    <ScrollAnimation animateIn="animate__fadeInRight">
                      <CardBody plain>
                        <h2 className={classes.cardTitle}>
                          Vigorous Academics{" "}
                        </h2>

                        <p className={classes.description}>
                          The College spares no effort to provide excellent
                          academic inputs to students. In addition to the
                          regular faculty members, there are a considerable
                          number of visiting and adjunct faculty from industry
                          and academia giving valuable contributions to students
                          and faculty.
                        </p>
                      </CardBody>
                      <CardFooter profile plain>
                        <Button color="primary" size="lg" href="#/courses-page">
                          Find Your Program
                          <ArrowRightAltIcon
                            style={{ marginLeft: "20px", marginRight: "-20px" }}
                          />
                        </Button>
                      </CardFooter>
                    </ScrollAnimation>
                  </GridItem>
                </GridContainer>
              </Card>
            </GridItem>
          </GridContainer>
        </div>
      </div>
      {/* Team 3 END */}
    </div>
  );
}
