import React, { useEffect, useState } from "react";
// react component for creating beautiful carousel
import { Domain } from "Domain";
import Carousel from "react-slick";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import Muted from "components/Typography/Muted.js";
import CardAvatar from "components/Card/CardAvatar.js";

import testimonialsStyle from "assets/jss/material-kit-pro-react/views/sectionsSections/testimonialsStyle.js";
import axios from "axios";

const useStyles = makeStyles(testimonialsStyle);
// const Domain = "http://rahulrajrahu33.pythonanywhere.com";

export default function SectionTestimonials({ ...rest }) {
  const classes = useStyles();
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: false,
  };
  const [testimonials, setTestimonials] = useState([]);
  useEffect(() => {
    let passData = {
      PageIndex: 0,
      PageSize: 10,
    };
    //API call for get latest 10 elements
    axios({
      method: "post",
      url: Domain + "/api/Admin/GetAllTestimonials.php",
      data: JSON.stringify(passData),
      config: { headers: { "Content-Type": "multipart/form-data" } },
    })
      .then(function (response) {
        console.log("succcess",response.data)
        setTestimonials(response.data.Data);
        // if (json.Data.length == 0) setEmpty(true);
      
      })
      .catch(function (response) {
        //handle error
        console.log(response);
      });
    // fetch(Domain + "/api/Admin/GetAllTestimonials/", {
    //   method: "POST",
    //   headers: {
    //     Accept: "application/json",
    //     "Content-Type": "application/json",
    //   },
    //   body: JSON.stringify(passData),
    // })
    //   .then((response) => response.json())

    //   .then((json) => {
    //     setTestimonials(json.Data);
    //     // console.log(json.Data);
    //     // if (json.Data.length == 0) setEmpty(true);
    //     // setLoading(false);
    //   });
  }, []);
  return (
    <div className="cd-section" {...rest}>
      {/* Testimonials 2 START */}
      <div
        className={
          classes.testimonials +
          " " +
          classes.sectionDark +
          " " +
          classes.testimonial2
        }
      >
        <div className={classes.container}>
          <GridContainer>
            <GridItem xs={12} sm={12} md={12}>
              <Carousel {...settings}>
                {testimonials.map((item) => (
                  <div>
                    <Card testimonial plain className={classes.card2}>
                      <CardAvatar testimonial plain>
                        <a href="##pablo" onClick={(e) => e.preventDefault()}>
                          <img src={Domain + item.image} alt={"..."} />
                        </a>
                      </CardAvatar>
                      <CardBody plain>
                        <Muted>
                          <h5 className={classes.cardDescription}>
                            {item.name}
                          </h5>
                        </Muted>
                        <h4 className={classes.cardDescription}>
                          {item.message}
                        </h4>
                        <h4 className={classes.cardTitle}>
                          {item.description}
                        </h4>
                      </CardBody>
                    </Card>
                  </div>
                ))}
              </Carousel>
            </GridItem>
          </GridContainer>
        </div>
      </div>
      {/* Testimonials 2 END */}
    </div>
  );
}
