import React, { useEffect } from "react";
import { HashRouter, Route, Switch, Redirect } from "react-router-dom";

// core components

// pages for this product
import AboutUsPage from "views/AboutUsPage/AboutUsPage.js";
import LandingPage from "views/LandingPage/LandingPage.js";
import ErrorPage from "views/ErrorPage/ErrorPage.js";
import CoursesPage from "views/CoursesPage/CoursesPage";
// import AdmissionsPage from "views/AdmissionsPage/AdmissionsPage";
import PlacementsPage from "views/PlacementsPage/PlacementsPage";
import PublicationsPage from "views/PublicationsPage/PublicationsPage";
import ContactUsPage from "views/ContactUsPage/ContactUsPage";
import EventsPage from "views/EventsPage/EventsPage";
import GalleryPage from "views/GalleryPage/GalleryPage";
import FacultyPage from "views/FacultyPage/FacultyPage";
import OnlineAdmissionPage from "views/OnlineAdmissionPage/OnlineAdmissionPage";

import ComputerLabPage from "views/Facilities/ComputerLabPage";
import OfficePage from "views/Facilities/OfficePage";
import LibraryPage from "views/Facilities/LibraryPage";
import CSMPage from "views/ClubsPage/CSMPage";
import NSSPage from "views/ClubsPage/NSSPage";
import WomenCellPage from "views/ClubsPage/WomenCellPage";
import Header from "components/Header/Header";
import HeaderLinks from "components/Header/HeaderLinks";
import ProfilePage from "views/ProfilePage/ProfilePage";
import { Domain } from "Domain";
import Loader from "components/Loader/Loader";
import DepartmentPage from "views/DepartmentPage/DepartmentPage";
import AdministrationsPage from "views/AdministrationsPage/AdministrationsPage";
import MessagesPage from "views/MessagesPage/MessagesPage";
import MissionAndVission from "views/Mission&VissionPage/Mission&VissionPage";
import axios from "axios";

export default function App() {
  const [loggedIn, setLoggedin] = React.useState(false);
  const [userdetails, setUserdetails] = React.useState([]);
  const [loading, setLoading] = React.useState(true);
  const [carousels, setCarousels] = React.useState([]);

  useEffect(() => {
    let passData = {
      PageIndex: 0,
      PageSize: 10,
    };
    //API call for get latest 10 elements
    axios({
      method: "post",
      url: Domain + "/api/Admin/GetAllCorousal.php",
      data: JSON.stringify(passData),
      config: { headers: { "Content-Type": "multipart/form-data" } },
    })
      .then(function (response) {
        console.log("succcess",response.data)
        setCarousels(response.data.Data);
        // if (json.Data.length == 0) setEmpty(true);
        setLoading(false);
      })
      .catch(function (response) {
        //handle error
        console.log(response);
      });
    // fetch(Domain + "/api/Admin/GetAllCorousal.php", {
    //   method: "POST",
    //   headers: {
    //     Accept: "application/json",
    //     "Content-Type": "application/json",
    //   },
    //   body: JSON.stringify(passData),
    // })
    //   .then((response) => response.json())

    //   .then((json) => {
    //     console.log("succcess")
    //     setCarousels(json.Data);
    //     // if (json.Data.length == 0) setEmpty(true);
    //     setLoading(false);
    //   });
      
  }, []);
  return (
    <>
      <HashRouter>
        <>
          {loading ? (
            <>
              <Loader />
            </>
          ) : (
            <>
              {loggedIn ? (
                <>
                  <Header
                    color="transparent"
                    brand={require("assets/img/logo.png")}
                    links={
                      <HeaderLinks
                        userdetails={userdetails}
                        loggedIn={loggedIn}
                        setLoggedin={setLoggedin}
                        setUserdetails={setUserdetails}
                        dropdownHoverColor="primary"
                      />
                    }
                    fixed
                    changeColorOnScroll={{
                      height: 100,
                      color: "primary",
                    }}
                  />
                  <Switch>
                    <Route path="/profile-page" component={ProfilePage} />
                    <Route path="/about-us" component={AboutUsPage} />
                    <Route path="/contact-us" component={ContactUsPage} />
                    <Route path="/" exact>
                      <LandingPage carousels={carousels} />
                    </Route>
                    <Route path="/error-page" component={ErrorPage} />
                    <Route path="/courses-page" component={CoursesPage} />
                    {/* <Route path="/admissions-page" component={AdmissionsPage} /> */}
                    <Route path="/placements-page" component={PlacementsPage} />
                    <Route
                      path="/publications-page"
                      component={PublicationsPage}
                    />
                    <Route path="/Events-page" component={EventsPage} />
                    <Route path="/gallery-page" component={GalleryPage} />
                    <Route path="/faculty-page" component={FacultyPage} />
                    <Route
                      path="/online-admission"
                      component={OnlineAdmissionPage}
                    />
                    <Route path="/department" component={DepartmentPage} />
                    <Route path="/Computer-lab" component={ComputerLabPage} />
                    <Route path="/Office-page" component={OfficePage} />
                    <Route path="/Library-page" component={LibraryPage} />
                    <Route path="/club-CSM" component={CSMPage} />
                    <Route path="/club-NSS" component={NSSPage} />
                    <Route path="/club-Womencell" component={WomenCellPage} />
                    <Route
                      path="/administrations-page"
                      component={AdministrationsPage}
                    />
                    <Route path="/Messages-page" component={MessagesPage} />
                    <Route
                      path="/Mission and Vission-page"
                      component={MissionAndVission}
                    />
                  </Switch>
                </>
              ) : (
                <>
                  <Header
                    color="transparent"
                    brand={require("assets/img/logo.png")}
                    links={
                      <HeaderLinks
                        userdetails={userdetails}
                        loggedIn={loggedIn}
                        setLoggedin={setLoggedin}
                        setUserdetails={setUserdetails}
                        dropdownHoverColor="primary"
                      />
                    }
                    fixed
                    changeColorOnScroll={{
                      height: 100,
                      color: "primary",
                    }}
                  />

                  <Switch>
                    <Route path="/about-us" component={AboutUsPage} />
                    <Route path="/contact-us" component={ContactUsPage} />
                    <Route path="/" exact>
                      <LandingPage carousels={carousels} />
                    </Route>
                    <Route path="/error-page" component={ErrorPage} />
                    <Route path="/courses-page" component={CoursesPage} />
                    {/* <Route path="/admissions-page" component={AdmissionsPage} /> */}
                    <Route path="/placements-page" component={PlacementsPage} />
                    <Route
                      path="/publications-page"
                      component={PublicationsPage}
                    />
                    <Route path="/Events-page" component={EventsPage} />
                    <Route path="/gallery-page" component={GalleryPage} />
                    <Route path="/faculty-page" component={FacultyPage} />
                    <Route
                      path="/online-admission"
                      component={OnlineAdmissionPage}
                    />
                    <Route path="/department" component={DepartmentPage} />

                    <Route path="/Computer-lab" component={ComputerLabPage} />
                    <Route path="/Office-page" component={OfficePage} />
                    <Route path="/Library-page" component={LibraryPage} />
                    <Route path="/club-CSM" component={CSMPage} />
                    <Route path="/club-NSS" component={NSSPage} />
                    <Route path="/club-Womencell" component={WomenCellPage} />
                    <Route
                      path="/administrations-page"
                      component={AdministrationsPage}
                    />
                    <Route path="/Messages-page" component={MessagesPage} />
                    <Route
                      path="/Mission and Vission-page"
                      component={MissionAndVission}
                    />
                    <Redirect path="/profile-page" to="/" />
                  </Switch>
                </>
              )}
            </>
          )}
        </>
      </HashRouter>
    </>
  );
}
