import React from "react";
// react component for creating beautiful carousel
import { Domain } from "Domain";
import Carousel from "react-slick";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// @material-ui/icons
import ArrowRightAltIcon from "@material-ui/icons/ArrowRightAlt";
// core components
import Button from "components/CustomButtons/Button.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import headersStyle from "assets/jss/material-kit-pro-react/views/landingPageSections/heroStyle.js";
import Marquee from "react-double-marquee";
import { Link } from "react-router-dom";
const useStyles = makeStyles(headersStyle);
export default function Hero({ ...rest }) {
  const classes = useStyles();
  const settings = {
    dots: true,
    infinite: true,
    speed: 600,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
  };

  return (
    // we've set the className to cd-section so we can make smooth scroll to it
    <div className="cd-section" {...rest}>
      {/* HEADER 3 START */}
      <div>
        <Carousel {...settings}>
          {/* Carousel 1 START */}
          {rest.carousels.map((item) => (
            <div>
              <div
                className={classes.pageHeader}
                style={{ backgroundImage: `url("${Domain + item.image}")` }}
              >
                <div className={classes.container}>
                  <GridContainer>
                    <GridItem xs={12} sm={4} md={4}>
                      <h1 className={classes.title}>{item.quote}</h1>

                      <br />
                      <Button
                        color="primary"
                        size="lg"
                        href={"#/" + item.buttonlink}
                      >
                        {item.buttontext}
                        <ArrowRightAltIcon
                          style={{ marginLeft: "20px", marginRight: "-20px" }}
                        />
                      </Button>
                    </GridItem>
                    {/* <GridItem xs={12} sm={12} md={12}>
                      <div
                        className={classes.container}
                        style={{
                          width: "600px",
                          backgroundColor: "#3e64ff",
                          color: "white",
                          whiteSpace: "nowrap",
                        }}
                      >
                        <h3 className={classes.title}>
                          <Link
                            to="online-admission"
                            style={{
                              color: "white",
                            }}
                          >
                            <Marquee>Admissions Started for 2022 batch</Marquee>
                          </Link>
                        </h3>
                      </div>
                    </GridItem> */}
                  </GridContainer>
                </div>
              </div>
            </div>
          ))}
        </Carousel>
      </div>
      {/* HEADER 3 END */}
    </div>
  );
}
