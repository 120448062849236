import React from "react";
import ClipLoader from "react-spinners/ClipLoader";
import presentationStyle from "assets/jss/material-kit-pro-react/views/presentationStyle.js";
import { makeStyles } from "@material-ui/core";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import Parallax from "components/Parallax/Parallax";
const useStyles = makeStyles(presentationStyle);
export default function Loader() {
  const classes = useStyles();
  return (
    <Parallax className={classes.parallax}>
      <div className={classes.container}>
        <GridContainer>
          <GridItem>
            <div className={classes.brand}>
              <ClipLoader color={"#3e64ff"} loading={true} size={55} />
            </div>
          </GridItem>
        </GridContainer>
      </div>
    </Parallax>
  );
}
