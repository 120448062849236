/* eslint-disable */
import React, { useEffect, useState } from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import { Domain } from "Domain";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import Pagination from "components/Pagination/Pagination";
import projectsStyle from "assets/jss/material-kit-pro-react/views/landingPageSections/placementStyle.js";

import { LazyLoadImage } from "react-lazy-load-image-component";
import axios from "axios";
const useStyles = makeStyles(projectsStyle);
// const Domain = "http://rahulrajrahu33.pythonanywhere.com";
export default function PlacedStudents({ ...rest }) {
  const classes = useStyles();

  const [placement, setPlacements] = useState([]);
  const [pageIndex, setPageIndex] = useState(0);
  const [pagination, setPagination] = useState(false);
  //const clasround = useStylesround();
  //data from json file

  useEffect(() => {
    let passData = {
      PageIndex: pageIndex,
      PageSize: 8,
    };
    //API call for get latest 10 elements

    axios({
      method: "post",
      url: Domain + "/api/Admin/GetAllPlacements.php",
      data: JSON.stringify(passData),
      config: { headers: { "Content-Type": "multipart/form-data" } },
    })
      .then(function (response) {
        console.log("Placements::",response.data.Data)
        setPlacements(response.data.Data);
        if(response.data.Data.length > 2) setPagination(true);
        // if (json.Data.length == 0) setEmpty(true);
      
      })
      .catch(function (response) {
        //handle error
        console.log(response);
      });
    // fetch(Domain + "/api/Admin/GetAllPlacements/", {
    //   method: "POST",
    //   headers: {
    //     Accept: "application/json",
    //     "Content-Type": "application/json",
    //   },
    //   body: JSON.stringify(passData),
    // })
    //   .then((response) => response.json())

    //   .then((json) => {
    //     setPlacements(json.Data);
    //     if (json.Data.length > 2) setPagination(true);
    //     // if (json.Data.length == 0) setEmpty(true);
    //     // setLoading(false);
    //   });
  }, [pageIndex]);

  return (
    <div className="cd-section" {...rest}>
      <div
        className={
          classes.projects + " " + classes.sectionDark + " " + classes.projects3
        }
      >
        <div className={(classes.containerm, classes.containerLimit)}>
          <GridContainer>
            {placement.map((item, i) => (
              <GridItem
                xs={12}
                sm={4}
                md={3}
                className={
                  classes.mlAuto +
                  " " +
                  classes.mrAuto +
                  " " +
                  classes.textCenter
                }
              >
                <GridContainer>
                  <GridItem xs={12} sm={12} md={12}>
                    <Card pricing className={classes.card}>
                      <CardBody pricing>
                        <LazyLoadImage
                          effect="blur"
                          src={Domain + item.image}
                          alt="..."
                          className={classes.imgFluid}
                        />
                        <GridItem xs={12} sm={12} md={12}>
                          <GridContainer>
                            <GridItem xs={12} sm={12} md={12}>
                              <h3 className={classes.title2}> {item.name} </h3>
                              <h4 className={classes.title2}>
                                {" "}
                                {item.company}{" "}
                              </h4>
                            </GridItem>
                          </GridContainer>
                        </GridItem>
                      </CardBody>
                    </Card>
                  </GridItem>
                </GridContainer>
              </GridItem>
            ))}
          </GridContainer>
          {pagination ? (
            <Pagination
              setPageIndex={setPageIndex}
              pageIndex={pageIndex}
              className={
                classes.textCenter + " " + classes.justifyContentCenter
              }
              pages={[
                { text: "Previous" },
                { active: true, text: "1" },

                { text: "Next" },
              ]}
              color="primary"
            />
          ) : (
            <></>
          )}
        </div>
      </div>
    </div>
  );
}
