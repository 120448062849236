import React from "react";
// @material-ui/core components
import { Domain } from "Domain";
import { makeStyles } from "@material-ui/core/styles";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import { LazyLoadImage } from "react-lazy-load-image-component";

import sectionInterestedStyle from "assets/jss/material-kit-pro-react/views/blogPostsSections/sectionInterestedStyle.js";
// const Domain = "http://rahulrajrahu33.pythonanywhere.com";

const useStyles = makeStyles(sectionInterestedStyle);

export default function Gallery({ gallery }) {
  const classes = useStyles();
  console.log(gallery);
  return (
    <div className={classes.section}>
      <GridContainer>
        {gallery.map((item, i) => (
          <GridItem xs={12} sm={4} md={4} lg={4} xl={4}>
            <Card plain blog>
              <CardHeader plain image>
                <a href="##pablo">
                  <LazyLoadImage src={Domain + item.Image} alt="..." />
                </a>
                <div
                  className={classes.coloredShadow}
                  style={{
                    backgroundImage: "url(" + Domain + item.Image + ")",
                    opacity: "1",
                  }}
                />
              </CardHeader>
            </Card>
          </GridItem>
        ))}
      </GridContainer>
    </div>
  );
}
